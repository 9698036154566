@media (min-width: 1400px) {
    .container {
        max-width: 1300px;
    }
}

body {
    margin: 0;
    padding: 0;
    font: normal normal normal 13px/18px "Manrope", sans-serif;
    color: #000000;
}
h1,h2,h3,h4,h5,h6{
    font-family: "Manrope", sans-serif !important;
}
.mt_40 {
    margin-top: 40px;
}

@media (max-width: 576px) {
    .mt_40 {
        margin-top: 0;
    }
    .nav_active{
        right: 0;
        left:unset;
    }
}

.mt_50 {
    margin-top: 50px;
}

.mt_75 {
    margin-top: 75px;
}

ul,
li {
    margin: 0;
    padding: 0;
    list-style: none;
}

ul.main_list_item>li {
    font-size: 15px;
    font-weight: 400;
    line-height: 1.8;
    color: #333;
    margin-bottom: 15px;
    padding-left: 20px;
    position: relative;
}

ul.main_list_item>li::before {
    content: "";
    position: absolute;
    height: 7px;
    width: 7px;
    border-radius: 50%;
    background-color: #ee7435;
    top: 15px;
    left: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

ul.sublist>li {
    font-size: 15px;
    font-weight: 500;
    color: #333;
    padding-left: 20px;
    position: relative;
}

ul.sublist>li::before {
    content: "";
    position: absolute;
    height: 7px;
    width: 7px;
    border-radius: 50%;
    background-color: #999999;
    top: 15px;
    left: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

button:hover,
button:active,
button:focus,
button:visited,
button {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    outline: none;
}

a,a:link,a:hover,a:active,a:focus,
a:visited {
    text-decoration: none;
    cursor: pointer;
    color: #111111;
}

@media (max-width: 576px) {
    .for_web {
        display: none !important;
    }
}

@media (min-width: 577px) {
    .for_web {
        display: block !important;
    }
}

@media (max-width: 576px) {
    .for_mobile {
        display: block !important;
    }
}

@media (min-width: 577px) {
    .for_mobile {
        display: none !important;
    }
}

@media (max-width: 576px) {
    .web {
        display: none !important;
    }
}

@media (min-width: 577px) {
    .web {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
    }
}

@media (max-width: 576px) {
    .mobile {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
    }
}

@media (min-width: 577px) {
    .mobile {
        display: none !important;
    }
}

@media (max-width: 576px) {
    .mt_15 {
        margin-top: 15px;
    }
}

.inner_container {
    height: 100%;
    width: 100%;
}

@media (max-width: 576px) {
    .inner_container {
        background-size: contain;
    }
}

@media (min-width: 577px) {
    .inner_container {
        max-width: 270px;
    }
}

@media (min-width: 768px) {
    .inner_container {
        max-width: 360px;
    }
}

@media (min-width: 992px) {
    .inner_container {
        max-width: 480px;
    }
}

@media (min-width: 1200px) {
    .inner_container {
        max-width: 570px;
    }
}

@media (min-width: 1400px) {
    .inner_container {
        max-width: 700px;
    }
}

.request_demo {
    padding: 3px 15px;
    background-color: #fff;
    color: #664fd9!important;
    border: 1px solid #664fd9;
    border-radius: 4px;
    font-size: 16px;
    margin-right: 1rem;
    min-width: 170px;
    text-align: center;
    height: 30px;
}

.request_demo:hover {
    color: #fff;
}

.footer_city {
    height: 160px;
    overflow: hidden;
}

.show-hide {
    padding: 2px;
    color: #444;
    letter-spacing: 0.5px;
    text-decoration: underline;
}

.client_login {
    padding: 3px 15px;
    background-color: #664fd9;
    color: #fff !important;
    border: 1px solid #664fd9;
    border-radius: 4px;
    font-size: 16px;
    min-width: 85px;
    text-align: center;
    height: 30px;
}

@media (min-width: 577px) {
    .client_login {
        margin-right: 25px;
    }
}

@media (min-width: 992px) {
    .client_login {
        margin-right: 35px;
    }
}

@media (min-width: 1200px) {
    .client_login {
        margin-right: 40px;
    }
}

.client_login:hover {
    color: #fff;
}

header.header {
    position: fixed;
    top: 0;
    background-color: #fff;
    width: 100%;
    z-index: 10;
}

header.sub-header {
    position: fixed;
    top: 64px;
    background-color: #fff;
    width: 100%;
    z-index: 8;
}


@media (max-width: 576px) {
    header.header {
        height: 50px;
    }
    header.sub-header {
        top: 60px;
        max-width: 100vw;
    }
}

@media (min-width: 577px) {
    header.header {
        height: 50px;
    }
}

header.header nav {
    -webkit-box-shadow: 0px 1px 3px #00000029;
    box-shadow: 0px 1px 3px #00000029;
    height: 100%;
}

header.header nav .navbar {
    height: 100%;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: auto;
}

@media (max-width: 576px) {
    header.header nav .navbar {
        padding: 0 15px;
    }
}

@media (min-width: 577px) {
    header.header nav .navbar {
        padding: 0 20px;
    }
}

header.header nav .navbar .fa-bars {
    font-size: 25px;
    color: #676767;
}

@media (max-width: 576px) {
    header.header nav .navbar .navbar-header {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
}

header.header nav .navbar .navbar-header .navbar-brand {
    left: 20px;
    top: 0;
    padding: 0;
}

@media (max-width: 576px) {
    header.header nav .navbar .navbar-header .navbar-brand {
        position: static;
    }
}

@media (min-width: 577px) {
    header.header nav .navbar .navbar-header .navbar-brand {
        position: absolute;
    }
}

@media (max-width: 576px) {
    header.header nav .navbar .navbar-header .navbar-brand img {
        -webkit-box-shadow: none;
        box-shadow: none;
        height: 35px;
    }
}

@media (min-width: 577px) {
    header.header nav .navbar .navbar-header .navbar-brand img {
        height: 110px;
        -webkit-box-shadow: 0px 4px 8px #00000017;
        box-shadow: 0px 4px 8px #00000017;
    }
}

header.header nav .navbar .nav-links {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

@media (max-width: 576px) {
    header.header nav .navbar .nav-links {
        position: fixed;
        top: 50px;
        right: -100%;
        display: block;
        max-width: 270px;
        width: 100%;
        background: #f9f9f9;
        line-height: 40px;
        -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
        -webkit-transition: all 0.5s ease;
        transition: all 0.5s ease;
        z-index: 9;
    }
}

header.header nav .navbar .nav-links .btn_close {
    position: absolute;
    top: 0px;
    right: 15px;
    font-size: 30px;
    color: #494949;
}

header.header nav .navbar .links {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
}

@media (max-width: 576px) {
    header.header nav .navbar .links {
        display: none;
    }
}

header.header nav .navbar .links li {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    list-style: none;
    padding: 0 20px;
    -webkit-transition: all linear 0.2s;
    transition: all linear 0.2s;
}

@media (min-width: 577px) {
    header.header nav .navbar .links li {
        padding: 0 10px;
    }
}

@media (min-width: 992px) {
    header.header nav .navbar .links li {
        padding: 0 15px;
    }
}

@media (min-width: 1200px) {
    header.header nav .navbar .links li {
        padding: 0 20px;
    }
}

header.header nav .navbar .links li.active>a {
    color: #ee7435;
}

header.header nav .navbar .links li a {
    color: #000;
    font-size: 15px;
    letter-spacing: 0.5px;
}

header.header nav .navbar .links li a:hover {
    color: #ee7435 !important;
    background-color: transparent;
}

@media (max-width: 576px) {
    header.header nav .navbar .links li a {
        padding: 0 0px;
    }
}

@media (max-width: 717px) {
    header.header nav .navbar .links li a {
        font-size: 13px!important;
        letter-spacing: -.5px!important;
    }
}

header.header nav .navbar .links li .sub-menu {
    position: absolute;
    top: 50px;
    left: 0;
    line-height: 40px;
    background: #fff;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    display: none;
    z-index: 2;
    min-width: 200px;
}

@media (max-width: 576px) {
    header.header nav .navbar .links li .sub-menu {
        background: #e1e1e1;
    }
}

header.header nav .navbar .links li .sub-menu li {
    border-bottom: 1px solid #e9e9e9;
}

header.header nav .navbar .links li .sub-menu li:hover {
    -webkit-transition: all linear 0.2s;
    transition: all linear 0.2s;
    background-color: #00000008;
}

header.header nav .navbar .links li .sub-menu li a {
    color: #111;
    font-size: 13px;
    display: block;
}

header.header nav .navbar .links li.lang {
    cursor: pointer;
}

@media (max-width: 576px) {
    header.header nav .navbar .links li.lang {
        display: none;
    }
}

header.header nav .navbar .links li.lang .active {
    color: #ee7435;
}

@media (max-width: 576px) {
    header.header nav .navbar .links li.lang {
        padding: 0 30px;
    }
}

header.header nav .navbar .links li:last-child {
    padding-right: 0;
}

header.header nav .navbar .links.nav_mob {
    display: none;
}

@media (max-width: 576px) {
    header.header nav .navbar .links.nav_mob {
        display: block;
        overflow: auto;
    }
    .mob_sub{
        margin: 20px -20px -20px;
        background: #fff !important;
    }
    .mob_sub li{
        padding: 0px 20px !important;
    }
    .mob_sub li:active{
        color: #f79041;
    }
}

header.header nav .navbar .links.nav_mob li {
    padding: 19px 20px;
    border-bottom: 1px solid #dfdfdf;
}
.new-heading {
    font-size: 1.25rem !important;
    font-weight: 900 !important;
    color: #664FD9 !important;
  }

header.sub-header .sub_nav {
    height:49px !important;
    max-height: 100px !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack:flex-end;
    -ms-flex-pack:flex-end;
    justify-content: flex-end;
    background-color: #eaeaea;
    position: relative;
    z-index: -1;

}
header .sub_nav_header {
    background-color:#f5ac86!important;
    width: 35% !important;
    height: 100% !important;
    text-align: right !important;
    padding: 15px !important;
    font-size: 14px !important;
    font-weight: bold !important;
    color: white !important;
    border-right: 3px solid #3774e8!important;
}
[dir=rtl] header .sub_nav_header {
    background-color: #F9B57A !important;
    width: 35% !important;
    height: 100% !important;
    text-align: left !important;
    padding: 15px !important;
    font-size: 14px !important;
    font-weight: bold !important;
    color: white !important;
    border-left: 3px solid #644fe8 !important;
    border-right: none !important;
    direction: rtl;
    margin-left: 5px;
}

header.sub-header .sub_nav ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-right: 50px;
    width: 70% !important;
    height: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

@media (max-width: 576px) {
    header.sub-header .sub_nav ul {
        margin-right: 0px;
        display: none;
    }
    header.sub-header .sub_nav {
        display: none;

    }
}

@media (min-width: 577px) {
    header.sub-header .sub_nav ul {
        margin-right: 0px;
    }
}

@media (min-width: 992px) {
    header.sub-header .sub_nav ul {
        margin-right: 0px;
    }
}

@media (min-width: 1200px) {
    header.sub-header .sub_nav ul {
        margin-right: -5px;
    }
}

header.sub-header .sub_nav ul li {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    width: 100%;
    justify-content: space-evenly;
    cursor: pointer;
}



@media (max-width: 576px) {
    header.sub-header.sub_nav ul li {
        padding: 0 15px;
        word-break: keep-all;
        white-space: nowrap;
    }
}

header.sub-header .sub_nav ul li a {
    font-size: 16px;
    color: #555;
    line-height: 3;
}

@media (min-width: 577px) {
    header.sub-header .sub_nav ul li a {
        padding: 0 0px;
        font-size: 10px!important;
        /* font-weight: bold; */
        line-height: 50px;
        width: 100%;
        text-align: center;
    }
}

@media (min-width: 577px) {
    header.header nav .navbar .links li a {
        font-size: 14px!important;
    }
}

@media (min-width: 912px) {
    header.header nav .navbar .links li a {
        font-size: 15px!important;
    }
}

@media (min-width: 992px) {
    header.sub-header .sub_nav ul li a {
        padding: 0 0px;
        width: 100%;
        text-align: center;
    }
}

@media (min-width: 1200px) {
    header.sub-header .sub_nav ul li a {
        padding: 4px 0px;
        font-size: 14px !important;
        line-height: 40px !important;
        white-space: nowrap;
        width: 100%;
        text-align: center;
    }
}



header.sub-header .sub_nav ul li:hover a {
    color: #111;
}

header.sub-header .sub_nav ul li.active {
    background-color: #fff;
}

header.sub-header .sub_nav ul li:hover:not(.active)  {
    color: #111;
    background-color: lightgray;
}

header.sub-header .sub_nav ul li.active a {
    color: #111;
}
.sub_nav_mobile{
    display: none;
}
@media (max-width: 576px) {
    header.header .sub_nav {
        display: none;
    }
    .sub_nav_mobile{
        display: block;
    }
    .request_demo_mobile{
        position: fixed;
        bottom: 0;
        width: 100%;
        /* height: 45px; */
        z-index: 1;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
    .request_demo_mobile .client_login{
        padding-bottom: 15px;
        background-color: #644fe8;
        color: #fff !important;
        border: 0px solid #644fe8;
        border-radius: 0px;
        font-size: 16px;
        width: 50%;
        height: 40px;
        line-height: 2;
        text-align: center;
    }
    .request_demo_mobile .request_demo{
        padding-bottom: 15px;
        background-color: #ee7435;
        color: #fff !important;
        border: 0px solid #e88f6f;
        border-radius: 0px;
        font-size: 16px;
        width: 50%;
        height: 40px;
        line-height: 2;
        text-align: center;
        margin-right: 0rem;
    }
    .sub_nav_mobile div.submenu {
        background: #EEEEEE;
        height: 45px;
    }
    .sub_nav_mobile div.submenu .active_div{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 13px 15px;
        max-width: 100vw;
    }
    .sub_nav_mobile div.submenu .active_div a{
        color: #000;
        font-weight: 600;
    }
    .submenu_drop{
        background: #f9f9f9;
        margin-top: -5px;
    }
    .submenu_drop.none{
        display: none;
    }
    .submenu_drop ul li a{
        padding: 2px 15px;
        color: #000;
        line-height: 2.9;
        display: block;
        border-top: 1px solid #ddd;
    }
    .submenu_drop.open{
        display: block;
    }
}
.none{
    display: none !important;
}

header.header nav .navbar .links li:hover .htmlCss-sub-menu,
header.header nav .navbar .links li:hover .js-sub-menu {
    display: block;
}
header.header nav .navbar .links li:hover .fa-chevron-down{
   transform: rotate(180deg);
}
@media (max-width: 576px) {
    header.header nav .navbar .mob {
        display: block !important;

    }
    .footermenu{
        background: #F0F0F0;
        padding: 15px 0;
    }
    .footermenu ul li{
        padding: 5px 20px !important;
        border: none !important;
    }
    .footermenu p{
        padding: 10px 20px;
        font-size: 10px;
    }
}
@media (min-width: 577px) {
    header.header nav .navbar .mob {
        display: none !important;

    }
}

@media (max-width: 576px) {
    header.header nav .navbar .mob {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
    header.header nav .navbar .mob .lang_mob {
        margin-right: 20px;
    }
    header.header nav .navbar .mob .lang_mob span {
        color: #aaaaaa;
    }
    header.header nav .navbar .mob .lang_mob span.active {
        color: #ee7435;
    }
    .mobile-header .lang_mob{
        margin-right: 20px;
    }
}

header.header .navbar .links li .sub-menu .more-arrow {
    line-height: 40px;
}

header.header .navbar .links li .sub-menu .more-sub-menu {
    position: absolute;
    top: 0;
    left: 100%;
    border-radius: 0 4px 4px 4px;
    z-index: 1;
    display: none;
}

header.header .links li .sub-menu .more:hover .more-sub-menu {
    display: block;
}

header.header .navbar .search-box {
    position: relative;
    height: 40px;
    width: 40px;
}

header.header .navbar .nav-links .sidebar-logo {
    display: none;
}

header.header .navbar .bx-menu {
    display: none;
}

@media (max-width: 576px) {
    header.header .navbar .bx-menu {
        display: block;
    }
    header.header .navbar .nav-links .sidebar-logo {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
    header.header nav .navbar .links li {
        display: block;
        padding: 0;
    }
    header.header nav .navbar .links li .sub-menu {
        position: relative;
        top: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
        display: none;
    }
    header.header .navbar .links li .sub-menu .more-sub-menu {
        display: none;
        position: relative;
        left: 0;
    }
    header.header .navbar .links li .sub-menu .more-sub-menu li {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
    header.header .navbar .links li .sub-menu .more-sub-menu {
        display: none;
    }
    header.header .navbar .links li .sub-menu .more span {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
    header.header .links li .sub-menu .more:hover .more-sub-menu {
        display: none;
    }
    header.header .navbar .nav-links.show1 .links .htmlCss-sub-menu,
    header.header .navbar .nav-links.show3 .links .js-sub-menu,
    header.header .navbar .nav-links.show2 .links .more .more-sub-menu {
        display: block;
    }
}

section.top_banner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-shadow: 0px 1px 0px #7c6eb07a;
    box-shadow: 0px 1px 0px #7c6eb07a;
}

@media (max-width: 576px) {
    section.top_banner {
        margin-top: 50px;
    }
}

@media (min-width: 577px) {
    section.top_banner {
        margin-top: 50px;
    }
}

@media (max-width: 576px) {
    section.top_banner.mobile .carousel-indicators {
        display: none;
    }
}

section.top_banner #carouselExampleControls {
    width: 100%;
}

section.top_banner #carouselExampleControls .carousel_arrow span {
    background: none;
}

section.top_banner #carouselExampleControls .carousel_arrow span .mat-icon {
    color: #000;
    font-size: 30px;
}

section.top_banner #carouselExampleControls .carousel-indicators {
    margin-bottom: 0;
    z-index: 1;
}

@media (max-width: 576px) {
    section.top_banner #carouselExampleControls .carousel-indicators {
        bottom: -35px;
    }
}

section.top_banner #carouselExampleControls .carousel-indicators li {
    background-color: #242424;
    width: 7px;
    height: 6px;
    border-radius: 50%;
    margin: 0 8px;
}

section.top_banner .banner {
    background-repeat: no-repeat;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

@media (max-width: 576px) {
    section.top_banner .banner {
        width: 100%;
        height: 380px;
        text-align: center;
    }
}

@media (min-width: 577px) {
    section.top_banner .banner {
        width: 50%;
        padding-top: 75px;
        height: 360px;
    }
}

@media (min-width: 1200px) {
    section.top_banner .banner {
        height: 360px;
        background-size: 350px;
    }
}

@media (min-width: 1400px) {
    section.top_banner .banner {
        height: 500px;
        background-size: 550px;
    }
}

section.top_banner .banner .btn_explore {
    border: 1px solid;
    font-size: 19px;
    line-height: 27px;
    color: #ffffff;
    border-radius: 6px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

@media (max-width: 576px) {
    section.top_banner .banner .btn_explore {
        margin: 35px auto;
        padding: 10px 20px;
    }
}

@media (min-width: 577px) {
    section.top_banner .banner .btn_explore {
        margin-top: 15px;
        padding: 8px 15px;
    }
}

@media (min-width: 992px) {
    section.top_banner .banner .btn_explore {
        padding: 8px 18px;
    }
}

@media (min-width: 1200px) {
    section.top_banner .banner .btn_explore {
        margin-top: 50px;
        padding: 8px 20px;
    }
}

section.top_banner .banner .btn_explore img {
    width: 20px;
    margin: 0 15px;
    -ms-interpolation-mode: nearest-neighbor;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-pixelated;
    image-rendering: pixelated;
}

section.top_banner .banner.bnr_1 {
    background-color: #fff4eb;
    -webkit-box-pack:flex-end;
    -ms-flex-pack:flex-end;
    justify-content:flex-end;
}

section.top_banner .banner.bnr_1 .inner_container {
    background-image: url("./../images/bnr_1.svg");
    background-position: bottom -1px right 20px;
    background-repeat: no-repeat;
    padding-left: 15px;
    background-size: 90% !important;
}

 @media (max-width: 576px) {
    /* section.top_banner .banner.bnr_1 .inner_container {
        background-size: 100% !important;
    } */
    section.top_banner .banner.bnr_1 {
        background-color: #fff4eb;
        -webkit-box-pack:flex-end;
        -ms-flex-pack:flex-end;
        justify-content:flex-end;
        min-width: 104% !important;
    }
    section.top_banner .banner.bnr_1 .inner_container p {
        margin-bottom: 0;
        margin-left: -20px !important;
    }

    section.top_banner .banner.bnr_1 .inner_container .explore_em {
        background-color: #ee7435;
        margin-left: -20px !important;
    }
}

/*@media (min-width: 577px) {
    section.top_banner .banner.bnr_1 .inner_container {
        background-size: 100%;
    }
}

@media (min-width: 992px) {
    section.top_banner .banner.bnr_1 .inner_container {
        background-size: 100%;
    }
}

@media (min-width: 1200px) {
    section.top_banner .banner.bnr_1 .inner_container {
        background-size: 100%;
    }
} */



section.top_banner .banner.bnr_1 .inner_container p {
    margin-bottom: 0;
}

section.top_banner .banner.bnr_1 .inner_container .explore_em {
    background-color: #ee7435;
}

section.top_banner .banner.bnr_1 .inner_container .explore_em:hover {
    background-color: #f79041;
}

section.top_banner .banner.bnr_2 {
    background-color: #f3fbfd;
    -webkit-box-pack: flex-start;
    -ms-flex-pack: flex-start;
    justify-content: flex-start;
    padding-left: 30px;
}

section.top_banner .banner.bnr_2 .inner_container {
    background-image: url("./../images/bnr_2.svg");
    background-position: bottom -1px right 15px;
    background-repeat: no-repeat;
    background-size: 90% !important;
    padding-right: 15px;
}

@media (max-width: 576px) {
    section.top_banner .banner.bnr_2 .inner_container {
        padding-left: 15px;
        background-size: 100%;
        background-position: bottom -1px right 15px;
    }
    section.top_banner .banner.bnr_2 {
        background-color: #f3fbfd;
        -webkit-box-pack: flex-start;
        -ms-flex-pack: flex-start;
        justify-content: flex-start;
        min-width: 104% !important;
        padding-left: 10px;
    }

    section.top_banner .banner.bnr_2 .inner_container p {
        margin-bottom: 0;
        margin-left: -30px !important;
    }

    section.top_banner .banner.bnr_2 .inner_container .explore_mi {
        background-color: #ee7435;
        margin-left: -20px !important;
    }
}

/* @media (min-width: 577px) {
    section.top_banner .banner.bnr_2 .inner_container {
        padding-left: 30px;
    }
}

@media (min-width: 992px) {
    section.top_banner .banner.bnr_2 .inner_container {
        padding-left: 45px;
    }
}

@media (min-width: 1200px) {
    section.top_banner .banner.bnr_2 .inner_container {
        padding-left: 60px;
    }
} */

section.top_banner .banner.bnr_2 .inner_container .explore_mi {
    background-color: #644fe8;
}

section.top_banner .banner.bnr_2 .inner_container .explore_mi:hover {
    background-color: #521e80;
}

section.top_banner .banner h1 {
    font-weight: 600;
    color: #333;
    line-height: 40px;
}

@media (max-width: 576px) {
    section.top_banner .banner h1 {
        font-size: 30px;
        margin-bottom: 0;
        margin-top: 35px;
        margin-left: -30px !important;
    }
}

@media (min-width: 577px) {
    section.top_banner .banner h1 {
        font-size: 30px;
    }
}

@media (min-width: 1200px) {
    section.top_banner .banner h1 {
        font-size: 32px;
    }
}

@media (min-width: 1400px) {
    section.top_banner .banner h1 {
        font-size: 32px;
        font-weight: 600;
    }
}

section.top_banner .banner p {
    color: #555;
    margin-bottom: 0;
}

@media (max-width: 576px) {
    section.top_banner .banner p {
        font-size: 20px;
        line-height: 25px;
        margin: 5px 0;
    }
}

@media (min-width: 577px) {
    section.top_banner .banner p {
        font-size: 20px;
        line-height: 30px;
    }
}

@media (min-width: 1200px) {
    section.top_banner .banner p {
        font-size: 23px;
    }
}

@media (max-width: 576px) {
    section.popular_trends {
        padding: 15px 0 30px;
        /* height:540px; */
    }
}

@media (min-width: 577px) {
    section.popular_trends {
        margin: 60px 0 100px 0;
    }
}

@media (min-width: 1200px) {
    section.popular_trends {
        margin: 75px 0 60px 0;
    }
}

section.popular_trends .trends_container .trends_heading {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-top: 30px;
}

section.popular_trends .trends_container .trends_heading .heading h2 {
    font-size: 25px;
    color: #222;
    line-height: 32px;
    margin-bottom: 5px;
}


@media (max-width: 576px) {
    section.popular_trends .trends_container .trends_heading .heading h2 {
        font-size: 20px;
    }
}

section.popular_trends .trends_container .trends_heading .arrow .mat-icon{
    font-size: 40px;
    color: #cccccc;
}

section.popular_trends .trends_container .trends_heading .arrow .fa:first-child {
    margin-right: 25px;
}

@media (max-width: 576px) {
    section.popular_trends .trends_container .trends_heading .arrow .fa:first-child {
        margin-right: 22px;
    }
}

@media (max-width: 576px) {
    section.popular_trends .trends_container .trends_heading .arrow .mat-icon {
        font-size: 30px;
    }
}

section.popular_trends .trends_container .trends_column .trends_slider {
    height: 350px;
    margin: 0;
}

@media (max-width: 576px) {
    section.popular_trends .trends_container .trends_column .trends_slider {
        height: auto;
    }
}

section.popular_trends .trends_container .trends_column .trends_slider .slick-list {
    height: 100%;
}

@media (max-width: 576px) {
    section.popular_trends .trends_container .trends_column .slick-dots {
        display: none !important;
    }
    section.popular_trends .trends_container .trends_column.trends_column_slider .slick-dots {
        display: block !important;
        top:calc(100% - 5px);
    }
    .trends_column_slider .slick-dots li ,
    .trends_column_slider .slick-dots li button::before {
        height: 12px;
        width: 12px;        
    }
    .trends_column_slider .slick-dots li{
        margin: 0px 3px;
    }
    .trends_column_slider .slick-dots li button {
        height: 12px;
        width: 12px;
        padding: 3px;
    }
    .trends_column_slider .slick-dots li button::before {
        font-size: 14px !important;
    }

  
}

@media (min-width: 577px) {
    section.popular_trends .trends_container .trends_column .slick-dots {
        bottom: 0px;
    }
}

@media (min-width: 1200px) {
    section.popular_trends .trends_container .trends_column .slick-dots {
        bottom: -15px !important;
    }
}

section.popular_trends .trends_container .trends_column .item {
    overflow: hidden;
    border-radius: 7px;
    position: relative;
}

@media (max-width: 576px) {
    section.popular_trends .trends_container .trends_column .item {
        height: 322px;
        width: 103% !important;
    }
}

@media (min-width: 577px) {
    section.popular_trends .trends_container .trends_column .item {
        height: 350px;
    }
}

@media (min-width: 1200px) {
    section.popular_trends .trends_container .trends_column .item {
        height: 322px;
    }
}

section.popular_trends .trends_container .trends_column .item img {
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
}

section.popular_trends .trends_container .trends_column .item.item_half {
    height: 150px;
}

@media (max-width: 576px) {
    section.popular_trends .trends_container .trends_column .item.item_half {
        margin-bottom: 8px !important;
        height:158px 

    }
    section.popular_trends .trends_container .trends_column .item.item_half:last-child {
        margin-bottom: 0;
    }
}

@media (min-width: 577px) {
    section.popular_trends .trends_container .trends_column .item.item_half {
        margin-bottom: 50px;
    }
}

@media (min-width: 1200px) {
    section.popular_trends .trends_container .trends_column .item.item_half {
        margin-bottom: 22px;
    }
}

section.popular_trends .trends_container .trends_column .item .overlay {
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    background-color: #00000082;
    z-index: 1;
    height: 100%;
}

section.popular_trends .trends_container .trends_column .item .item_content {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 1;
}

section.popular_trends .trends_container .trends_column .item .item_content h3 {
    font-size: 18px;
    font-weight: 600;
    color: #fff;
}

section.popular_trends .trends_container .trends_column .item .item_content p {
    margin-bottom: 5px;
    color: #fff;
    font-size: 15px;
}

@media (max-width: 576px) {
    section.popular_trends .trends_container .trends_column .item .item_content p {
        text-align: justify;
    }
}

section.popular_trends .trends_container .trends_column .nav-tabs {
    border: none;
    margin-top: 25px;
    margin-bottom: 45px;
    padding-right: 2.5rem;
}
section.popular_trends .trends_container .trends_column .nav-tabs li.active a{
    background: #E2DCFF 0% 0% no-repeat padding-box;
    color: #111111;
}

section.popular_trends .trends_container .trends_column .nav-tabs li a {
    letter-spacing: 0px;
    font-size: 1.1rem;
    line-height: 24px;
    border-radius: 5px;
    padding: 8px 30px;
    color: #666666;
}
@media (max-width: 576px) {
    section.popular_trends .trends_container .trends_column .nav-tabs li a {
        font-size: 16px;
        line-height: 22px;
    }
}
section.popular_trends .trends_container .trends_column .tab-content .tab-pane.active {
    opacity: 1 !important;
}

section.popular_reports {
    padding: 60px 0 30px 0;
    background-color: #f3f2f6;
    /* height:450px; */
    /* height: 550px; */
}



 @media (max-width: 767px) {
    section.popular_reports {
        padding: 40px 0;
        margin-top: 40px;
        /* height: 535px; */
    }
    section.popular_reports .carousel h3 {
        display: none;
    }
    section.popular_reports .carousel .slick-active h3{
                display: block;
             }
} 


footer {
    background-color: #f8f8f8;
    padding: 30px 0 25px 0;
}

footer .footer_subheading {
    margin-bottom: 40px;
}

footer .footer_subheading h2 {
    font-size: 23px;
    line-height: 24px;
    color: #444;
}

@media (max-width: 576px) {
    footer .explore_list {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
}

footer .explore_list ul li {
    font-size: 14px;
    margin-bottom: 15px;
}

@media (max-width: 576px) {
    footer .explore_list ul li {
        display: block;
    }
}

footer .explore_list ul li a {
    color: #444;
    letter-spacing: 0.5px;
}

footer .explore_list ul li a:hover {
    border-bottom: 1px solid #444;
}

footer .explore_list .footer_tabing {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 35px;
    border-bottom: 1px solid #d2d2d2;
}
@media (max-width: 767px){
    footer .explore_list .footer_tabing{
        overflow-x: scroll;
    }
}

footer .explore_list .footer_tabing li {
    cursor: pointer;
    margin-right: 40px;
    margin-bottom: 0;
    color: #444;
    padding: 10px;
}
footer .explore_list .footer_tabing li:hover {
    border-bottom: 1px solid #ee7435;
}

footer .explore_list .footer_tabing li.active {
    border-bottom: 1px solid #ee7435;
    font-weight: 600;
}

footer .explore_list .footer_bahrain {
    display: none;
}

footer hr {
    margin-top: 20px;
    border-top: 2px solid #CEC9DB;
}

@media (max-width: 576px) {
    footer hr {
        margin-top: 1rem;
    }
}

footer .about_estater .about_heading {
    margin-bottom: 40px;
    margin-top: 20px;
}

footer .about_estater .about_heading h2 {
    font-size: 25px;
    line-height: 24px;
    color: #444;
}

footer .about_estater ul li {
    padding-bottom: 20px;
    font-size: 14px;
    line-height: 19px;
}

footer .about_estater ul li:first-child {
    font-weight: 600;
    color: #000;
    padding-bottom: 30px;
}

footer .about_estater ul li a {
    color: #333 !important;
    letter-spacing: 0.5px;
    cursor: pointer;
}

footer .about_estater ul li a:hover {
    border-bottom: 1px solid #333;
}

@media (max-width: 576px) {
    footer .about_estater .col-6:last-child ul {
        margin-top: 0px;
    }
}

footer .footer_text {
    margin-bottom: 40px;
    margin-top: 20px;
}

@media (max-width: 576px) {
    footer .footer_text {
        margin-bottom: 10px;
        margin-top: 10px;
        text-align: left;
    }
}

footer .footer_text p {
    margin-bottom: 0;
    font-size: 0.8rem;
    color: #555;
}

footer .social_media_icon ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

@media (max-width: 576px) {
    .social_media_icon{
        padding-bottom: 10px;
    }
    footer .social_media_icon ul {
        margin-top: 15px;
    }
}

footer .social_media_icon ul li {
    padding: 0 20px;
}

footer .social_media_icon ul li:first-child {
    padding-left: 0;
}
footer.footer_ec .social_media_icon ul li{
    padding:5px !important;
}
footer.footer_ec .social_media_icon ul li a{
    padding:10px !important;
}
footer .social_media_icon ul li .mat-icon {
    color: #000;
    font-size: 17px;
}
footer.footer_ec .social_media_icon ul{
    float: right;
}
@media (max-width : 676px){
        footer.footer_ec .social_media_icon ul{
         float: none;
         justify-content: center;
        }
      } 
footer.footer_ec .social_media_icon ul li a:hover .fa{
    color: #6449DB;
}

.jquery-modal {
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.2);
}

@media (max-width: 576px) {
    .jquery-modal {
        padding: 0;
        border-radius: 0;
        background-color: #fff;
    }
    .jquery-modal .popup_request_demo {
        max-width: 100%;
        width: 100%;
        padding: 35px 20px;
        -webkit-box-shadow: none;
        box-shadow: none;
        height: 100% !important;
        border-radius: 0;
    }
    .jquery-modal .popup_request_demo .popup_close {
        top: 10px;
        right: 10px;
    }
}

.jquery-modal .modal {
    -webkit-box-shadow: 0 0 10px #00000047;
    box-shadow: 0 0 10px #00000047;
}

hr {
    height: 2px;
}

.banner_container {
    height: 100%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: relative;
}

@media (max-width: 576px) {
    .banner_container {
        -webkit-box-align: flex-start !important;
        -ms-flex-align: flex-start !important;
        align-items: flex-flex-start !important;
    }
}

@media (max-width: 576px) {
    .banner_container .banner-text {
        /* margin-top: 25px; */
    }
}

.btn_primary {
    background-color: #664fd9;
    border: none;
    padding: 12px 15px;
    border-radius: 6px;
    color: #fff;
    font-size: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}


/* Estater Meter*/

.em_banner {
    /* background: transparent -webkit-gradient (linear, left top, right top, from (#d1caf3), to(#ffffff)) 0% 0% no-repeat padding-box; */
    background: transparent linear-gradient(90deg, #d1caf3 0%, #ffffff 100%) 0% 0% no-repeat padding-box;
    margin-top: 85px;
    /* -webkit-box-shadow: 0px 1px 0px #cfb4a0; */
    box-shadow: 0px 1px 0px #9484e5;
}

@media (max-width: 576px) {
    .em_banner {
        height: 240px;
    }
}

@media (min-width: 577px) {
    .em_banner {
        height: 264px;
    }
}

@media (min-width: 992px) {
    .em_banner {
        height: 300px;
    }
}

.em_banner .banner-text {
    max-width: 444px;
}

@media (max-width: 576px) {
    .em_banner .banner-text {
        max-width: 239px;
    }
}

.em_banner .banner-text h3, .em_banner .banner-text h1.h3 {
    margin-bottom: 30px;
    color: #664fd9;
    font-size: 22px;
    line-height: 30px;
}

@media (max-width: 576px) {
    .em_banner .banner-text h3, .em_banner .banner-text h1.h3 {
        font-size: 20px;
        margin-bottom: 10px;
    }
}

@media (min-width: 577px) {
    .em_banner .banner-text h3 {
        max-width: 330px;
    }
}

.em_banner .banner-text img {
    width: 20px;
    margin-left: 15px;
    -ms-interpolation-mode: nearest-neighbor;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-pixelated;
    image-rendering: pixelated;
}

.em_banner .web_banner {
    position: absolute;
    right: 15px;
    bottom: 1px;

}

.feature_banner {
    margin-right: 0%;
    width: 90%;
    object-fit: contain;
}
.bank_company_banner { 
    margin-right: 0%;   
    width: 45%;
    object-fit: contain;
}

.center {
    display: block;
    margin-left: auto;
    margin-right: auto;    
  }

@media (max-width: 576px) {
    .em_banner .web_banner {
        width: 50%;
    }
    .feature_banner {
        width: 100%;
        object-fit: contain;
    }
    .bank_company_banner {          
        width: 100%;
        padding-top: 40px;
    }
    .dflex{
        display: flex;
        flex-direction: column-reverse;
    }
}

@media (min-width: 577px) {
    .em_banner .web_banner {
        height: calc(90% - 92px);
    }
    .feature_banner {
        height: calc(100% - 65px);
    }

    .bank_company_banner {
        height: calc(100% - 0px);
    }
   

}

.em_about {
    padding-top: 75px;
}


.em_offer {
    padding-bottom: 75px;
}


@media (max-width: 576px) {
    .em_about {
        padding-top: 40px;
    }
}

.em_about .heading h2 {
    font-size: 25px;
    line-height: 30px;
    color: #111;
    margin-bottom: 40px;
}

.em_offer .heading h2 {
    font-size: 25px;
    line-height: 27px;
    color: #111;
    margin-bottom: 40px;
}

.em_about .content p {
    line-height: 30px;
    font-size: 15px;
    color: #222222;
    margin-bottom: 30px;
    padding-right: 30px;
}

@media (max-width: 576px) {
    .em_about .content p {
        text-align: justify;
        padding-right: 0;
    }
}

.em_about .content a {
    font-size: 16px;
    text-decoration: underline;
    color: #5c2d83;
    margin-top: 25px;
    display: inline-block;
}

@media (max-width: 576px) {
    .em_about .content ul {
        margin-left: 0;
    }
}

.em_about .content ul li {
    line-height: 30px;
    color: #222;
    font-size: 14px;
    list-style: none;
    margin-bottom: 10px;
    position: relative;
    padding-left: 20px;
}

.em_offer .content ul li {
    line-height: 30px;
    color: #222;
    font-size: 14px;
    list-style: none;
    margin-bottom: 10px;
    position: relative;
    padding-left: 20px;
}

@media (max-width: 576px) {
    .em_about .content ul li {
        text-align: justify;
    }
    .em_offer .content ul li {
        text-align: justify;
    }
}

.em_about .content ul li::before {
    content: "";
    position: absolute;
    height: 7px;
    width: 7px;
    border-radius: 50%;
    background-color: #57ac52;
    top: 15px;
    left: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.em_offer .content ul li::before {
    content: "";
    position: absolute;
    height: 7px;
    width: 7px;
    border-radius: 50%;
    background-color: #ee7435;
    top: 15px;
    left: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.em_about .banner_image {
    text-align: center;
    margin-top: 25px;
}

.em_about .mt_75 {
    margin-top: 75px;
}

@media (max-width: 576px) {
    .how_it_works {
        padding: 40px 0;
    }
    .how_it_works .content p {
        text-align: justify;
        padding-right: 0;
    }
    
}

@media (min-width: 577px) {
    .how_it_works {
        padding: 60px 0;
    }
}

@media (min-width: 1200px) {
    .how_it_works {
        padding: 75px 0;
    }
}

.how_it_works .heading {
    width: 100%;
    margin: 0 auto;
}

.how_it_works .heading h2 {
    font-size: 25px;
    color: #111111;
    margin-bottom: 5px;
}

.how_it_works .banner_image {
    position: relative;
    margin: 0 auto;
}

.how_it_works .banner_image .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
}

.how_it_works .banner_image .em_banner_icon {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 1;
}

.how_it_works .content p {
    line-height: 30px;
    font-size: 15px;
    color: #222222;
    margin-bottom: 30px;
    padding-right: 30px;
}

@media (max-width: 576px) {
    .data_science_applied {
        padding: 40px 0;
    }
}

@media (min-width: 577px) {
    .data_science_applied {
        padding: 60px 0;
    }
}

@media (min-width: 1200px) {
    .data_science_applied {
        padding: 75px 0;
    }
}

.data_science_applied .heading h2 {
    font-size: 25px;
    text-align: center;
    color: #111111;
}

.data_science_applied .heading p {
    line-height: 30px;
    font-size: 15px;
    color: #222222;
    margin-bottom: 30px;
    text-align: center;
    margin: 40px auto 40px auto;
    width: 90%;
}

.data_science_applied .ds_col_header {
    max-width: 80%;
    margin: 0 auto;
}

.data_science_applied .ds_col_header button {
    height: 50px;
    width: 100%;
    border: 0.5px solid #777777;
    background-color: transparent;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 2px;
    font-size: 14px;
    -webkit-transition: all linear 0.2s;
    transition: all linear 0.2s;
    margin-bottom: 25px;
}

.data_science_applied .ds_col_header button:hover {
    -webkit-transition: all linear 0.2s;
    transition: all linear 0.2s;
    background-color: #92909e;
    color: #fff;
}

.feature_estater {
    background-color: #f3f2f6;
    padding: 50px 0;
}

.feature_estater .heading {
    margin-bottom: 40px;
}

.feature_estater .heading h2 {
    font-size: 25px;
    color: #111111;
}

.feature_estater .features_list {
    margin-bottom: 30px;
}

.feature_estater .features_list span {
    font-size: 14px;
    color: #333333;
    position: relative;
    display: inline-block;
    padding-left: 20px;
}

.feature_estater .features_list span::before {
    content: "";
    position: absolute;
    height: 7px;
    width: 7px;
    border-radius: 50%;
    background-color: #ee7435;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

section.avm_module {
    background-color: #5f5b6f;
    padding: 75px 0;
}

@media (max-width: 576px) {
    section.avm_module {
        padding: 40px 0;
    }
}

@media (min-width: 577px) {
    section.avm_module {
        padding: 60px 0;
    }
}

@media (min-width: 1200px) {
    section.avm_module {
        padding: 75px 0;
    }
}

section.avm_module .heading h2 {
    font-size: 25px;
    line-height: 34px;
    color: #ffffff;
}

section.avm_module .heading p {
    margin-top: 25px;
    font-size: 14px;
    line-height: 35px;
    color: #d9d9d9;
}

@media (max-width: 576px) {
    section.avm_module .heading p {
        text-align: justify;
    }
}

section.avm_module .card {
    background-color: transparent;
    padding: 20px;
    border-color: transparent;
    margin-bottom: 25px;
    border-radius: 12px;
    min-height: 230px;
    transition: all linear 0.2s;
    -webkit-transition: all linear 0.2s;
    -moz-transition: all linear 0.2s;
    -ms-transition: all linear 0.2s;
    -o-transition: all linear 0.2s;
}

section.avm_module .card .heading h2 {
    font-size: 20px;
    line-height: 27px;
    color: #ffffff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

section.avm_module .card .heading h2 .number {
    height: 49px;
    width: 49px;
    border: 1px solid #918da5;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-right: 20px;
    border-radius: 50%;
}

section.avm_module .card .content {
    margin-top: 20px;
}

section.avm_module .card .content p {
    color: #d9d9d9;
    font-size: 15px;
    line-height: 20px;
    max-width: 335px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

@media (max-width: 576px) {
    section.avm_module .card .content p {
        text-align: justify;
    }
}

section.avm_module .card .content a {
    margin-top: 15px;
    color: #ffffff;
    font-size: 14px;
    line-height: 19px;
    border-color: #ffffff;
}

section.avm_module .card .content ul li {
    color: #d9d9d9;
    font-size: 15px;
    line-height: 20px;
    position: relative;
    padding-left: 15px;
}

@media (max-width: 576px) {
    section.avm_module .card .content ul li {
        text-align: justify;
    }
}

section.avm_module .card .content ul li::before {
    content: "";
    position: absolute;
    height: 7px;
    width: 7px;
    border-radius: 50%;
    background-color: #d9d9d9;
    top: 10px;
    left: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

section.avm_module .card:hover {
    background: #5f5b6f 0% 0% no-repeat padding-box;
    -webkit-box-shadow: 0px 3px 6px #0000001a;
    box-shadow: 0px 3px 6px #0000001a;
    border-color: #767285;
    transition: all linear 0.2s;
    -webkit-transition: all linear 0.2s;
    -moz-transition: all linear 0.2s;
    -ms-transition: all linear 0.2s;
    -o-transition: all linear 0.2s;
}

section.feature_estater_meter {
    background-color: #cce6cb;
}

@media (max-width: 576px) {
    section.feature_estater_meter {
        padding: 40px 0;
    }
}

@media (min-width: 577px) {
    section.feature_estater_meter {
        padding: 60px 0;
    }
}

@media (min-width: 1200px) {
    section.feature_estater_meter {
        padding: 85px 0 50px 0;
    }
}

section.feature_estater_meter .heading {
    margin-bottom: 40px;
}

section.feature_estater_meter .heading h2 {
    font-size: 25px;
    color: #111111;
}

section.feature_estater_meter .prod_links {
    margin: auto;
    width: 100%;
}

section.feature_estater_meter .prod_links a {
    height: 193px;
    width: 256px;
    text-align: center;
    /* background-color: #fff;
    border: 1px solid #d5cbde; */
    border-radius: 6px;
    color: #645c7e;
    font-size: 16px;
    line-height: 153px;
    margin-bottom: 30px !important;
    margin-left: 50px;
}

section.feature_estater_meter .prod_links a p {
    margin-top: -40px;
    color: #444444;    
    font: normal normal normal 20px/29px "Manrope";
}

@media (max-width: 576px) {
    section.feature_estater_meter .prod_links a{
        margin-left: 0px;
    }
}

section.feature_estater_meter .prod_links a.active,
section.feature_estater_meter .prod_links a:hover {
    background-color: #fff;
    border-color: #ee7435;
    /* color: #fff; */
}

@media (max-width: 576px) {
    section.feature_estater_meter .prod_links a.active,
    section.feature_estater_meter .prod_links a:hover,section.feature_estater_meter .prod_links {
        background-color: transparent;
        border-color: transparent;
        color: #ee7435;
        border-bottom-color: #ee7435;       
    }
}

section.feature_estater_meter .prod_links .tab-content {
    margin-top: 40px;
}

section.feature_estater_meter .prod_links .tab-content .tab_pane {
    display: none;
}

section.feature_estater_meter .prod_links .tab-content .tab_pane:first-child {
    display: block;
}

section.feature_estater_meter .prod_links .tab-content .tab_pane .list {
    counter-reset: countnumber;
}

section.feature_estater_meter .prod_links .tab-content .tab_pane .list p {
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 25px;
    position: relative;
    padding-left: 20px;
}

section.feature_estater_meter .prod_links .tab-content .tab_pane .list p::before {
    counter-increment: countnumber;
    content: counter(countnumber) ". ";
    left: 0;
    top: 0;
    position: absolute;
}

section.feature_estater_meter .prod_links .tab-content p {
    font-size: 15px;
    line-height: 22px;
    text-align: justify;
}

section.feature_estater_meter .service img {
    height: 55px;
}

@media (max-width: 576px) {
    section.about_avm {
        margin: 40px 0;
    }
}

@media (min-width: 577px) {
    section.about_avm {
        margin: 60px 0;
    }
}

@media (min-width: 1200px) {
    section.about_avm {
        margin: 75px 0;
    }
}

section.about_avm .heading h2 {
    color: #000000;
    font-size: 25px;
    line-height: 34px;
}

section.about_avm .heading p {
    font-size: 15px;
    line-height: 30px;
    color: #333;
}

@media (max-width: 576px) {
    section.about_avm .heading p {
        text-align: justify;
    }
}

section.about_avm .heading a {
    font-size: 16px;
    color: #664fd9;
    margin-top: 25px;
    display: inline-block;
}

section.about_avm .heading a:hover {
    border-bottom: 1px solid #5c2d83;
}

section.about_avm .content {
    margin-top: 30px;
}

section.about_avm .content ul li {
    font-size: 15px;
    line-height: 25px;
    color: #333333;
    margin-top: 15px;
    padding-left: 20px;
    position: relative;
}

@media (max-width: 576px) {
    section.about_avm .content ul li {
        text-align: justify;
    }
}

section.about_avm .content ul li::before {
    content: "";
    position: absolute;
    height: 7px;
    width: 7px;
    border-radius: 50%;
    background-color: #858585;
    top: 10px;
    left: 0;
    background-color: #57ac52;
}

section.about_avm .content a {
    font-size: 16px;
    color: #664fd9;
    margin-top: 25px;
    display: inline-block;
}

section.about_avm .content a:hover {
    border-bottom: 1px solid #5c2d83;
}

section.about_avm .banner_image {
    text-align: center;      
}

@media (min-width: 577px) {
    section.about_avm .banner_image img {
        height: 75%;
        width: 60%;
    }
}

@media (min-width: 1200px) {
    section.about_avm .banner_image img {
        height: 100%;
        /* width: 100%; */
    }
}

section.about_avm .mt_75 {
    margin-top: 75px;
}

@media (max-width: 576px) {
    section.how_avm_works {
        margin-bottom: 40px;
    }
}

@media (min-width: 577px) {
    section.how_avm_works {
        margin-bottom: 60px;
    }
}

@media (min-width: 1200px) {
    section.how_avm_works {
        margin-bottom: 75px;
    }
}

section.how_avm_works .heading h2 {
    color: #000000;
    font-size: 25px;
    line-height: 34px;
}

section.how_avm_works .content {
    margin-top: 30px;
}

section.how_avm_works .content ul li {
    font-size: 15px;
    line-height: 25px;
    color: #333333;
    margin-top: 15px;
    padding-left: 20px;
    position: relative;
}

@media (max-width: 576px) {
    section.how_avm_works .content ul li {
        text-align: justify;
    }
}

section.how_avm_works .content ul li::before {
    content: "";
    position: absolute;
    height: 7px;
    width: 7px;
    border-radius: 50%;
    background-color: #858585;
    top: 10px;
    left: 0;
    background-color: #ee7435;
}

section.avm_objective {
    background-color: #ee7435;
}

@media (max-width: 576px) {
    section.avm_objective {
        padding: 40px 0;
    }
}

@media (min-width: 577px) {
    section.avm_objective {
        padding: 60px 0;
    }
}

@media (min-width: 1200px) {
    section.avm_objective {
        padding: 75px 0;
    }
}

section.avm_objective .heading h2 {
    color: #ffffff;
    font-size: 25px;
    line-height: 34px;
}

section.avm_objective .objective_list {
    margin-top: 30px;
}

section.avm_objective .objective_list ul.parent_list>li {
    font-size: 16px;
    line-height: 25px;
    color: #fff;
    margin-top: 20px;
    padding-left: 20px;
    position: relative;
}

@media (max-width: 576px) {
    section.avm_objective .objective_list ul.parent_list>li {
        text-align: justify;
    }
}

section.avm_objective .objective_list ul.parent_list>li::before {
    content: "";
    position: absolute;
    height: 7px;
    width: 7px;
    border-radius: 50%;
    background-color: #858585;
    top: 10px;
    left: 0;
    background-color: #fff;
}

section.avm_objective .objective_list ul.parent_list>li ul {
    counter-reset: my-sec-counter;
}

section.avm_objective .objective_list ul.parent_list>li ul li {
    margin-top: 20px;
    position: relative;
    padding-left: 20px;
}

@media (max-width: 576px) {
    section.avm_objective .objective_list ul.parent_list>li ul li {
        text-align: justify;
    }
}

section.avm_objective .objective_list ul.parent_list>li ul li::before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0;
    counter-increment: my-sec-counter;
    content: counter(my-sec-counter) ". ";
}

section.lst_col {
    background-color: #566590;
}

@media (max-width: 576px) {
    section.lst_col {
        padding: 40px 0;
    }
}

@media (min-width: 577px) {
    section.lst_col {
        padding: 60px 0;
    }
}

@media (min-width: 1200px) {
    section.lst_col {
        padding: 75px 0;
    }
}

section.lst_col .heading {
    margin-bottom: 50px;
}

section.lst_col .heading h2 {
    color: #ffffff;
    font-size: 25px;
    line-height: 34px;
}

section.lst_col .col_text {
    text-align: center;
    color: #ffffff;
    font-size: 17px;
    line-height: 23px;
    border: 1px solid #b9bcc4;
    border-radius: 3px;
    height: 220px;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0 15px;
}

section.lst_col .col_text img {
    margin-bottom: 25px;
}

section.challenges_benegit .heading {
    margin-bottom: 30px;
}

section.challenges_benegit .heading h2 {
    font-size: 25px;
    line-height: 34px;
}

section.challenges_benegit .list li {
    position: relative;
    padding-left: 20px;
    font-size: 15px;
    line-height: 25px;
    margin-top: 25px;
    color: #333;
}

@media (max-width: 576px) {
    section.challenges_benegit .list li {
        text-align: justify;
    }
}

section.challenges_benegit .list li::before {
    content: "";
    position: absolute;
    height: 7px;
    width: 7px;
    border-radius: 50%;
    background-color: #ee7435;
    top: 15px;
    left: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

section.challenges_benegit .cont_chll_benfit {
    height: 100%;
    width: 100%;
    padding: 0 15px;
}

@media (min-width: 577px) {
    section.challenges_benegit .cont_chll_benfit {
        max-width: 360px;
    }
}

@media (min-width: 992px) {
    section.challenges_benegit .cont_chll_benfit {
        max-width: 480px;
    }
}

@media (min-width: 1200px) {
    section.challenges_benegit .cont_chll_benfit {
        max-width: 570px;
    }
}

@media (min-width: 1400px) {
    section.challenges_benegit .cont_chll_benfit {
        max-width: 660px;
    }
}

section.challenges_benegit .col_challenges {
    background-color: #f2f2f2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack:flex-end;
    -ms-flex-pack:flex-end;
    justify-content:flex-end;
    padding: 75px 0;
    height: 100%;
}

@media (max-width: 576px) {
    section.challenges_benegit .col_challenges {
        padding: 40px 0;
    }
}

section.challenges_benegit .col_benefits {
    background-color: #e9e9e9;
    padding: 75px 0 50px 0;
    height: 100%;
}

@media (max-width: 576px) {
    section.challenges_benegit .col_benefits {
        padding: 40px 0;
    }
}

section.challenges_benegit .col_benefits .cont_chll_benfit {
    padding-left: 50px;
}

@media (max-width: 576px) {
    section.challenges_benegit .col_benefits .cont_chll_benfit {
        padding-left: 15px;
    }
}

@media (max-width: 576px) {
    .data_science section.about_data_science {
        margin: 40px 0;
    }
}

@media (min-width: 577px) {
    .data_science section.about_data_science {
        margin: 60px 0;
    }
}

@media (min-width: 1200px) {
    .data_science section.about_data_science {
        margin: 75px 0;
    }
}

.data_science section.about_data_science .heading h2 {
    color: #000000;
    font-size: 25px;
    line-height: 34px;
    margin-bottom: 25px;
}

.data_science section.about_data_science .heading p {
    font-size: 15px;
    line-height: 30px;
    color: #333;
}

@media (max-width: 576px) {
    .data_science section.about_data_science .heading p {
        text-align: justify;
    }
}

.data_science section.about_data_science .content {
    margin-top: 30px;
}

.data_science section.about_data_science .content ul li {
    font-size: 15px;
    line-height: 25px;
    color: #333333;
    margin-top: 15px;
    padding-left: 20px;
    position: relative;
}

@media (max-width: 576px) {
    .data_science section.about_data_science .content ul li {
        text-align: justify;
    }
}

.data_science section.about_data_science .content ul li::before {
    content: "";
    position: absolute;
    height: 7px;
    width: 7px;
    border-radius: 50%;
    top: 10px;
    left: 0;
    background-color: #57ac52;
}

.data_science section.avm_perspective {
    background-color: #ee7435;
}

@media (max-width: 576px) {
    .data_science section.avm_perspective {
        padding: 40px 0;
    }
}

@media (min-width: 577px) {
    .data_science section.avm_perspective {
        padding: 60px 0;
    }
}

@media (min-width: 1200px) {
    .data_science section.avm_perspective {
        padding: 75px 0;
    }
}

.data_science section.avm_perspective .heading {
    margin-bottom: 30px;
}

.data_science section.avm_perspective .heading h2 {
    font-size: 25px;
    line-height: 34px;
    color: #fff;
}

.data_science section.avm_perspective .perspective_list ul li {
    position: relative;
    padding-left: 0px;
    font-size: 15px;
    line-height: 25px;
    margin-top: 25px;
    color: #fff;
}

.data_science section.avm_perspective .perspective_list ul li::before {
    content: "";
    position: absolute;
    height: 7px;
    width: 7px;
    border-radius: 50%;
    top: 10px;
    left: 0;
    background-color: #fff;
    display: none;
}

@media (max-width: 576px) {
    .data_science section.concept_data {
        margin: 40px 0;
    }
    .data_science section.avm_perspective .perspective_list ul li {
        position: relative;
        padding-left: 0px;
        font-size: 15px;
        line-height: 25px;
        margin-top: 25px;
        color: #fff;
        text-align: justify;
    }
}

@media (min-width: 577px) {
    .data_science section.concept_data {
        margin: 60px 0;
    }
}

@media (min-width: 1200px) {
    .data_science section.concept_data {
        margin: 75px 0;
    }
}

.data_science section.concept_data .heading h2 {
    color: #000000;
    font-size: 25px;
    line-height: 34px;
    margin-bottom: 25px;
}

.data_science section.concept_data .content {
    margin-top: 30px;
}

.data_science section.concept_data .content ul li {
    font-size: 15px;
    line-height: 25px;
    color: #333333;
    margin-top: 15px;
    padding-left: 20px;
    position: relative;
}

@media (max-width: 576px) {
    .data_science section.concept_data .content ul li {
        text-align: justify;
    }
}
.data_science section.concept_data .heading p {
    font-size: 15px;
    line-height: 30px;
    color: #333;
}

.data_science section.concept_data .content ul li::before {
    content: "";
    position: absolute;
    height: 7px;
    width: 7px;
    border-radius: 50%;
    background-color: #858585;
    top: 10px;
    left: 0;
    background-color: #ee7435;
}

.data_science section.types_of_data .heading {
    margin-bottom: 30px;
}

.data_science section.types_of_data .heading p {
    font-size: 15px;
    line-height: 30px;
    color: #fff;
    width:70%;
    text-align: justify;
}
.data_science section.types_of_data .data_science_image{
    width: 50%;
    margin-top:0px;
}

.data_science section.types_of_data .data_science_image img{
    margin-left: 400px;   
}

.data_science section.types_of_data .heading h2 {
    font-size: 25px;
    line-height: 34px;
    color: #fff;
    text-align: justify;
}

.data_science section.types_of_data .list li {
    position: relative;
    padding-left: 20px;
    font-size: 15px;
    line-height: 25px;
    margin-top: 25px;
    color: #fff;
}

@media (max-width: 576px) {
    .data_science section.types_of_data .list li {
        text-align: justify;
        width:100%;
    }

    .data_science section.types_of_data .data_science_image img{
        margin-left: 0px;
        margin-top: 0px;
        width: 100%;
        object-fit: contain;
    }

    .data_science section.types_of_data .data_science_image{
        width: 100%;
        margin-top:0px;
    }

    .data_science section.types_of_data .heading p {
        font-size: 15px;
        line-height: 30px;
        color: #fff;
        width:100%;
        text-align: justify;
    }
}

.data_science section.types_of_data .list li::before {
    content: "";
    position: absolute;
    height: 7px;
    width: 7px;
    border-radius: 50%;
    background-color: #9acd97;
    top: 15px;
    left: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.data_science section.types_of_data .cont_chll_benfit {
    height: 100%;
    width: 100%;
    padding: 0px 100px;
}

@media (max-width: 576px) {
    .data_science section.types_of_data .cont_chll_benfit {
        height: 100%;
        width: 100%;
        padding: 0px 20px;
    }
}

@media (min-width: 577px) {
    .data_science section.types_of_data .cont_chll_benfit {
        max-width: 100%;
    }
}

@media (min-width: 992px) {
    .data_science section.types_of_data .cont_chll_benfit {
        max-width: 100%;
    }
}

@media (min-width: 1200px) {
    .data_science section.types_of_data .cont_chll_benfit {
        max-width: 100%;
    }
}

@media (min-width: 1400px) {
    .data_science section.types_of_data .cont_chll_benfit {
        max-width: 100%;
    }
}

.data_science section.types_of_data .col_typeofdata {
    background-color: #3774e8;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack:flex-start;
    -ms-flex-pack:flex-start;
    justify-content:flex-start;
    padding: 75px 0;
    height: 100%;
}

@media (max-width: 576px) {
    .data_science section.types_of_data .col_typeofdata {
        padding: 40px 0;
    }
}

.data_science section.types_of_data .col_app_datascience {
    background-color: #57ac52;
    padding: 75px 50px;
    height: 100%;
}

@media (max-width: 576px) {
    .data_science section.types_of_data .col_app_datascience {
        padding: 40px 30px;
    }
}

.data_science section.types_of_data .col_app_datascience .cont_chll_benfit {
    padding-left: 50px;
}

@media (max-width: 576px) {
    .data_science section.types_of_data .col_app_datascience .cont_chll_benfit {
        padding-left: 15px;
    }
}

.machine_learning {
    margin-top: 1px;    
}

.machine_learning section.automated_valuation .heading {
    margin-bottom: 20px;
    margin-top: 75px;
    padding: 20px;
}

.machine_learning section.automated_valuation .heading h2 {
    font-size: 25px;
    line-height: 34px;    
    margin-bottom: 25px;
}

.machine_learning section.automated_valuation .heading p {
    font-size: 15px;
    line-height: 30px;   
    margin-bottom: 0;
}

@media (max-width: 576px) {
    .machine_learning section.automated_valuation .heading p {
        text-align: justify;
    }
}

.machine_learning section.automated_valuation .list {
    counter-reset: countnumber;
}

.machine_learning section.automated_valuation .list li {
    position: relative;
    padding-left: 20px;
    font-size: 15px;
    line-height: 25px;
    margin-top: 25px;
    color: #fff;
}

@media (max-width: 576px) {
    .machine_learning section.automated_valuation .list li {
        text-align: justify;
    }
}

.machine_learning section.automated_valuation .list li::before {
    counter-increment: countnumber;
    content: counter(countnumber) ". ";
    left: 0;
    top: 0;
    position: absolute;
}

.machine_learning section.automated_valuation .cont_chll_benfit {
    height: 100%;
    width: 100%;
    padding: 0 15px;
}

@media (min-width: 577px) {
    .machine_learning section.automated_valuation .cont_chll_benfit {
        max-width: 360px;
    }
}

@media (min-width: 992px) {
    .machine_learning section.automated_valuation .cont_chll_benfit {
        max-width: 480px;
    }
}

@media (min-width: 1200px) {
    .machine_learning section.automated_valuation .cont_chll_benfit {
        max-width: 570px;
    }
}

@media (min-width: 1400px) {
    .machine_learning section.automated_valuation .cont_chll_benfit {
        max-width: 660px;
    }
}

.machine_learning section.automated_valuation .col_typeofdata {
    background-color: #566590;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack:flex-end;
    -ms-flex-pack:flex-end;
    justify-content:flex-end;
    padding: 75px 0;
    height: 100%;
}

@media (max-width: 576px) {
    .machine_learning section.automated_valuation .col_typeofdata {
        padding: 40px 0;
    }
}

.machine_learning section.automated_valuation .col_app_datascience {
    background-color: #fff;
    padding: 75px 0;
    height: 100%;
}

@media (max-width: 576px) {
    .machine_learning section.automated_valuation .col_app_datascience {
        padding: 40px 0;
    }
}

.machine_learning section.automated_valuation .col_app_datascience .cont_chll_benfit {
    padding-left: 50px;
}

@media (max-width: 576px) {
    .machine_learning section.automated_valuation .col_app_datascience .cont_chll_benfit {
        padding-left: 15x;
    }
}

.machine_learning section.ml_data_driven .heading { 
    padding: 10px;
}

.machine_learning section.ml_data_driven .heading h2 {
    font-size: 25px;
    line-height: 34px;   
    margin-bottom: 0px;
}

.machine_learning section.ml_data_driven .heading p {
    font-size: 15px;
    line-height: 30px;    
    margin-bottom: 0;
}

@media (max-width: 576px) {
    .machine_learning section.ml_data_driven .heading p {
        text-align: justify;
    }
}

.machine_learning section.ml_data_driven .list {
    padding: 20px;
}

.machine_learning section.ml_data_driven .list li {
    position: relative;
    padding-left: 20px;
    font-size: 15px;
    line-height: 25px;
    margin-top: 25px;    
}

@media (max-width: 576px) {
    .machine_learning section.ml_data_driven .list li {
        text-align: justify;
    }
}

.machine_learning section.ml_data_driven .list li::before {
    content: "";
    position: absolute;
    height: 7px;
    width: 7px;
    border-radius: 50%;
    background-color: #ee7435;
    top: 15px;
    left: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.machine_learning section.ml_data_driven .cont_chll_benfit {
    height: 100%;
    width: 100%;
    padding: 0 15px;
}

.machine_learning section.ml_valuation .cont_chll1 {
      
    background-color:#3774e8;
}
.machine_learning section.ml_valuation .cont_chll2 {
     
    background-color:#57ac52;
}

.machine_learning section.ml_valuation .heading { 
    padding: 0px;
}

.machine_learning section.ml_valuation .heading h2 {
    font-size: 25px;
    line-height: 34px;   
    margin-bottom: 20px;
    color: #fff;
}

.machine_learning section.ml_valuation .heading p {
    font-size: 15px;
    line-height: 30px;    
    margin-bottom: 0;
    color: #fff; 
}

@media (max-width: 576px) {
    .machine_learning section.ml_valuation .heading p {
        text-align: justify;
    }
}

.machine_learning section.ml_valuation .list {
    padding: 20px;
}

.machine_learning section.ml_valuation .list li {
    position: relative;
    padding-left: 20px;
    font-size: 15px;
    line-height: 25px;
    margin-top: 25px; 
    color: #fff;   
}

@media (max-width: 576px) {
    .machine_learning section.ml_valuation .list li {
        text-align: justify;
    }
}

.machine_learning section.ml_valuation .list li::before {
    content: "";
    position: absolute;
    height: 7px;
    width: 7px;
    border-radius: 50%;
    background-color: #87acf1;
    top: 15px;
    left: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}


@media (min-width: 577px) {
    .machine_learning section.ml_data_driven .cont_chll_benfit {
        max-width: 100%;
    }
}

@media (min-width: 992px) {
    .machine_learning section.ml_data_driven .cont_chll_benfit {
        max-width: 100%;
    }
}

@media (min-width: 1200px) {
    .machine_learning section.ml_data_driven .cont_chll_benfit {
        max-width: 100%;
    }
}

@media (min-width: 1400px) {
    .machine_learning section.ml_data_driven .cont_chll_benfit {
        max-width: 100%;
    }
}

.machine_learning section.ml_data_driven .col_typeofdata {
    background-color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack:flex-end;
    -ms-flex-pack:flex-end;
    justify-content:flex-end;
    padding: 75px 0;
    height: 100%;
}

@media (max-width: 576px) {
    .machine_learning section.ml_data_driven .col_typeofdata {
        padding: 40px 0;
    }
}

.machine_learning section.ml_data_driven .col_app_datascience {
    background-color: #ee7435;
    padding: 75px 0;
    height: 100%;
}

@media (max-width: 576px) {
    .machine_learning section.ml_data_driven .col_app_datascience {
        padding: 40px 0;
    }
}

.machine_learning section.ml_data_driven .col_app_datascience .cont_chll_benfit {
    padding-left: 50px;
}

@media (max-width: 576px) {
    .machine_learning section.ml_data_driven .col_app_datascience .cont_chll_benfit {
        padding-left: 15px;
    }
}

@media (max-width: 576px) {
    .mra section.about_mra {
        padding: 40px 0;
    }
}

@media (min-width: 577px) {
    .mra section.about_mra {
        padding: 60px 0;
    }
}

@media (min-width: 1200px) {
    .mra section.about_mra {
        padding: 75px 0;
    }
}

.mra section.about_mra .heading {
    margin-bottom: 20px;
}

.mra section.about_mra .heading h2 {
    font-size: 25px;
    line-height: 34px;
    color: #000;
    margin-bottom: 25px;
}

.mra section.about_mra .heading p {
    font-size: 15px;
    line-height: 30px;
    color: #333;
    margin-bottom: 0;
}

@media (max-width: 576px) {
    .mra section.about_mra .heading p {
        text-align: justify;
    }
}

.mra section.concept_mra {
    background-color: #57ac52;
    color: #fff;
}

@media (max-width: 576px) {
    .mra section.concept_mra {
        padding: 40px 0;
    }
}

@media (min-width: 577px) {
    .mra section.concept_mra {
        padding: 60px 0;
    }
}

@media (min-width: 1200px) {
    .mra section.concept_mra {
        padding: 75px 0;
    }
}

.mra section.concept_mra .heading h2 {
    font-size: 25px;
    line-height: 34px;
    margin-bottom: 25px;
}

.mra section.concept_mra .inner_content {
    margin-top: 30px;
}

.mra section.concept_mra .inner_content h3 {
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 0;
}

.mra section.concept_mra .inner_content ul {
    counter-reset: countnumber;
}

.mra section.concept_mra .inner_content ul li {
    position: relative;
    padding-left: 20px;
    font-size: 15px;
    line-height: 25px;
    margin-top: 15px;
    color: #fff;
}

@media (max-width: 576px) {
    .mra section.concept_mra .inner_content ul li {
        text-align: justify;
    }
}

.mra section.concept_mra .inner_content ul li::before {
    counter-increment: countnumber;
    content: counter(countnumber) ". ";
    left: 0;
    top: 0;
    position: absolute;
}

@media (max-width: 576px) {
    .mra section.multiple_regression {
        padding: 40px 0;
    }
}

@media (min-width: 577px) {
    .mra section.multiple_regression {
        padding: 60px 0;
    }
}

@media (min-width: 1200px) {
    .mra section.multiple_regression {
        padding: 75px 0;
    }
}

.mra section.multiple_regression .heading {
    margin-bottom: 20px;
}

.mra section.multiple_regression .heading h2 {
    font-size: 25px;
    line-height: 34px;
    color: #000;
    margin-bottom: 25px;
}

.mra section.multiple_regression .heading p {
    font-size: 15px;
    line-height: 30px;
    color: #333;
    margin-bottom: 0;
}

@media (max-width: 576px) {
    .mra section.multiple_regression .heading p {
        text-align: justify;
    }
}

.mra section.regression_anal .heading {
    margin-bottom: 20px;
}

.mra section.regression_anal .heading h2 {
    font-size: 25px;
    line-height: 34px;
    color: #fff;
    margin-bottom: 25px;
}

.mra section.regression_anal .heading p {
    font-size: 15px;
    line-height: 30px;
    color: #fff;
    margin-bottom: 0;
}

@media (max-width: 576px) {
    .mra section.regression_anal .heading p {
        text-align: justify;
    }
}

.mra section.regression_anal .list {
    counter-reset: countnumber;
}

.mra section.regression_anal .list li {
    position: relative;
    padding-left: 20px;
    font-size: 15px;
    line-height: 25px;
    margin-top: 25px;
    color: #fff;
}

@media (max-width: 576px) {
    .mra section.regression_anal .list li {
        text-align: justify;
    }
}

.mra section.regression_anal .list li::before {
    counter-increment: countnumber;
    content: counter(countnumber) ". ";
    left: 0;
    top: 0;
    position: absolute;
}

.mra section.regression_anal .cont_chll_benfit {
    height: 100%;
    width: 100%;
    padding: 0 15px;
}

@media (min-width: 577px) {
    .mra section.regression_anal .cont_chll_benfit {
        max-width: 360px;
    }
}

@media (min-width: 992px) {
    .mra section.regression_anal .cont_chll_benfit {
        max-width: 480px;
    }
}

@media (min-width: 1200px) {
    .mra section.regression_anal .cont_chll_benfit {
        max-width: 570px;
    }
}

@media (min-width: 1400px) {
    .mra section.regression_anal .cont_chll_benfit {
        max-width: 660px;
    }
}

.mra section.regression_anal .col_typeofdata {
    background-color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack:flex-end;
    -ms-flex-pack:flex-end;
    justify-content:flex-end;
    padding: 75px 0;
    height: 100%;
}

@media (max-width: 576px) {
    .mra section.regression_anal .col_typeofdata {
        padding: 40px 0;
    }
}

.mra section.regression_anal .col_app_datascience {
    background-color: #614B7D;
    padding: 75px 0;
    height: 100%;
}

@media (max-width: 576px) {
    .mra section.regression_anal .col_app_datascience {
        padding: 40px 0;
    }
}

.mra section.regression_anal .col_app_datascience .cont_chll_benfit {
    padding-left: 50px;
}

@media (max-width: 576px) {
    .mra section.regression_anal .col_app_datascience .cont_chll_benfit {
        padding-left: 15px;
    }
}

.mra section.performance_evaluation .heading {
    margin-bottom: 20px;
}

.mra section.performance_evaluation .heading h2 {
    font-size: 25px;
    line-height: 34px;
    color: #fff;
    margin-bottom: 25px;
}

.mra section.performance_evaluation .heading p {
    font-size: 15px;
    line-height: 30px;
    color: #fff;
    margin-bottom: 0;
}

@media (max-width: 576px) {
    .mra section.performance_evaluation .heading p {
        text-align: justify;
    }
}

.mra section.performance_evaluation .list {
    counter-reset: countnumber;
}

.mra section.performance_evaluation .list li {
    position: relative;
    padding-left: 20px;
    font-size: 15px;
    line-height: 25px;
    margin-top: 25px;
    color: #fff;
}

@media (max-width: 576px) {
    .mra section.performance_evaluation .list li {
        text-align: justify;
    }
}

.mra section.performance_evaluation .list li::before {
    counter-increment: countnumber;
    content: counter(countnumber) ". ";
    left: 0;
    top: 0;
    position: absolute;
}

.mra section.performance_evaluation .cont_chll_benfit {
    height: 100%;
    width: 100%;
    padding: 0 15px;
}

@media (min-width: 577px) {
    .mra section.performance_evaluation .cont_chll_benfit {
        max-width: 360px;
    }
}

@media (min-width: 992px) {
    .mra section.performance_evaluation .cont_chll_benfit {
        max-width: 480px;
    }
}

@media (min-width: 1200px) {
    .mra section.performance_evaluation .cont_chll_benfit {
        max-width: 570px;
    }
}

@media (min-width: 1400px) {
    .mra section.performance_evaluation .cont_chll_benfit {
        max-width: 660px;
    }
}

.mra section.performance_evaluation .col_typeofdata {
    background-color: #568290;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack:flex-end;
    -ms-flex-pack:flex-end;
    justify-content:flex-end;
    padding: 75px 0;
    height: 100%;
}

@media (max-width: 576px) {
    .mra section.performance_evaluation .col_typeofdata {
        padding: 40px 0;
    }
}

.mra section.performance_evaluation .col_app_datascience {
    background-color: #fff;
    padding: 75px 0;
    height: 100%;
}

@media (max-width: 576px) {
    .mra section.performance_evaluation .col_app_datascience {
        padding: 40px 0;
    }
}

.mra section.performance_evaluation .col_app_datascience .cont_chll_benfit {
    padding-left: 50px;
}

@media (max-width: 576px) {
    .mra section.performance_evaluation .col_app_datascience .cont_chll_benfit {
        padding-left: 15px;
    }
}

.mra section.mra_chall .heading {
    margin-bottom: 20px;
}

.mra section.mra_chall .heading h2 {
    font-size: 25px;
    line-height: 34px;
    color: #fff;
    margin-bottom: 25px;
}

.mra section.mra_chall .heading p {
    font-size: 15px;
    line-height: 30px;
    color: #fff;
    margin-bottom: 0;
}

@media (max-width: 576px) {
    .mra section.mra_chall .heading p {
        text-align: justify;
    }
}

.mra section.mra_chall .list {
    counter-reset: countnumber;
}

.mra section.mra_chall .list li {
    position: relative;
    padding-left: 20px;
    font-size: 15px;
    line-height: 25px;
    margin-top: 25px;
    color: #fff;
}

@media (max-width: 576px) {
    .mra section.mra_chall .list li {
        text-align: justify;
    }
}

.mra section.mra_chall .list li::before {
    counter-increment: countnumber;
    content: counter(countnumber) ". ";
    left: 0;
    top: 0;
    position: absolute;
}

.mra section.mra_chall .cont_chll_benfit {
    height: 100%;
    width: 100%;
    padding: 0 15px;
}

@media (min-width: 577px) {
    .mra section.mra_chall .cont_chll_benfit {
        max-width: 360px;
    }
}

@media (min-width: 992px) {
    .mra section.mra_chall .cont_chll_benfit {
        max-width: 480px;
    }
}

@media (min-width: 1200px) {
    .mra section.mra_chall .cont_chll_benfit {
        max-width: 570px;
    }
}

@media (min-width: 1400px) {
    .mra section.mra_chall .cont_chll_benfit {
        max-width: 660px;
    }
}

.mra section.mra_chall .col_typeofdata {
    background-color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack:flex-end;
    -ms-flex-pack:flex-end;
    justify-content:flex-end;
    padding: 75px 0;
    height: 100%;
}

@media (max-width: 576px) {
    .mra section.mra_chall .col_typeofdata {
        padding: 40px 0;
    }
}

.mra section.mra_chall .col_app_datascience {
    background-color: #707e47;
    padding: 75px 0;
    height: 100%;
}

@media (max-width: 576px) {
    .mra section.mra_chall .col_app_datascience {
        padding: 40px 0;
    }
}

.mra section.mra_chall .col_app_datascience .cont_chll_benfit {
    padding-left: 50px;
}

@media (max-width: 576px) {
    .mra section.mra_chall .col_app_datascience .cont_chll_benfit {
        padding-left: 15px;
    }
}

.mra section.mra_bnefit .heading {
    margin-bottom: 20px;
}

.mra section.mra_bnefit .heading h2 {
    font-size: 25px;
    line-height: 34px;
    color: #fff;
    margin-bottom: 25px;
}

.mra section.mra_bnefit .heading p {
    font-size: 15px;
    line-height: 30px;
    color: #fff;
    margin-bottom: 0;
}

@media (max-width: 576px) {
    .mra section.mra_bnefit .heading p {
        text-align: justify;
    }
}

.mra section.mra_bnefit .list {
    counter-reset: countnumber;
}

.mra section.mra_bnefit .list li {
    position: relative;
    padding-left: 20px;
    font-size: 15px;
    line-height: 25px;
    margin-top: 25px;
    color: #fff;
}

@media (max-width: 576px) {
    .mra section.mra_bnefit .list li {
        text-align: justify;
    }
}

.mra section.mra_bnefit .list li::before {
    counter-increment: countnumber;
    content: counter(countnumber) ". ";
    left: 0;
    top: 0;
    position: absolute;
}

.mra section.mra_bnefit .cont_chll_benfit {
    height: 100%;
    width: 100%;
    padding: 0 15px;
}

@media (min-width: 577px) {
    .mra section.mra_bnefit .cont_chll_benfit {
        max-width: 360px;
    }
}

@media (min-width: 992px) {
    .mra section.mra_bnefit .cont_chll_benfit {
        max-width: 480px;
    }
}

@media (min-width: 1200px) {
    .mra section.mra_bnefit .cont_chll_benfit {
        max-width: 570px;
    }
}

@media (min-width: 1400px) {
    .mra section.mra_bnefit .cont_chll_benfit {
        max-width: 660px;
    }
}

.mra section.mra_bnefit .col_typeofdata {
    background-color: #6e6f5b;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack:flex-end;
    -ms-flex-pack:flex-end;
    justify-content:flex-end;
    padding: 75px 0;
    height: 100%;
}

@media (max-width: 576px) {
    .mra section.mra_bnefit .col_typeofdata {
        padding: 40px 0;
    }
}

.mra section.mra_bnefit .col_app_datascience {
    background-color: #fff;
    padding: 75px 0;
    height: 100%;
}

@media (max-width: 576px) {
    .mra section.mra_bnefit .col_app_datascience {
        padding: 40px 0;
    }
}

.mra section.mra_bnefit .col_app_datascience .cont_chll_benfit {
    padding-left: 50px;
}

@media (max-width: 576px) {
    .mra section.mra_bnefit .col_app_datascience .cont_chll_benfit {
        padding-left: 15px;
    }
}


/* About MI*/

section.MI_banner_design {
    background-color: #c3d5f8;
    -webkit-box-shadow: 0px 1px 0px #c3d5f8;
    box-shadow: 0px 1px 0px #749eef;
    margin-top: 85px;
}

@media (max-width: 576px) {
    section.MI_banner_design {
        height: 250px;
    }
}

@media (min-width: 577px) {
    section.MI_banner_design {
        height: 250px;
    }
}

@media (min-width: 1200px) {
    section.MI_banner_design {
        height: 300px;
    }
}

section.MI_banner_design .banner-text {
    max-width: 361px;
}

@media (max-width: 576px) {
    section.MI_banner_design .banner-text {
        max-width: 269px;
        /* margin-top: 15px; */
    }
}

section.MI_banner_design .banner-text h1 {
    margin-bottom: 30px;
    color: #222222;
    font-size: 22px;
    line-height: 30px;
}

@media (max-width: 576px) {
    section.MI_banner_design .banner-text h1 {
        font-size: 1.25rem;
        margin-bottom: 20px;
    }
}

@media (min-width: 577px) {
    section.MI_banner_design .banner-text h1 {
        max-width: 330px;
    }
}

@media (min-width: 1200px) {
    section.MI_banner_design .banner-text h1 {
        max-width: 600px;
    }
}

section.MI_banner_design .web_banner {
    position: absolute;
    right: 15px;
    bottom: 0;
}

@media (max-width: 576px) {
    section.MI_banner_design .web_banner {
        width: 50%;
    }
}

@media (min-width: 577px) {
    section.MI_banner_design .web_banner {
        height: calc(100% - 65px);
    }
}

@media (min-width: 1200px) {
    section.MI_banner_design .web_banner {
        /* height: auto; */
    }
}

section.about_product {
    margin: 75px 0;
}

@media (max-width: 576px) {
    section.about_product {
        margin: 40px 0;
    }
}

section.about_product .heading h3 {
    margin-bottom: 50px;
    color: #222222;
    font-size: 25px;
    line-height: 30px;
}

@media (max-width: 576px) {
    section.about_product .heading h3 {
        margin-bottom: 30px;
    }
}

section.about_product .about_content {
    max-width: 400px;
}

section.about_product .about_content h3 {
    color: #ee7435;
    line-height: 30px;
    font-size: 25px;
    margin-bottom: 25px;
}

section.about_product .about_content p {
    line-height: 30px;
    font-size: 15px;
    color: #222222;
    margin-bottom: 30px;
}

@media (min-width: 577px) {
    section.about_product .about_content p {
        line-height: 25px;
        text-align: justify;
    }
}

section.product-detail {
    padding-bottom: 40px;
}

section.product-detail .leading-text {
    color: #222;
    font-size: 25px;
    margin-bottom: 0;
    padding: 0px 0 50px 0;
    text-align: center;
}

section.product-detail .prod-links {
    padding: 0px 0px 30px 0px;
    text-align: center;
    margin: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

@media (max-width: 576px) {
    section.product-detail .prod-links {
        padding-bottom: 0;
        margin-bottom: 30px;
    }
}

section.product-detail .prod-links ul li {
    text-decoration: none !important;
    font-size: 15px;
    border: 1px solid #d5cbde;
    border-radius: 3px;
    padding: 13px 0px;
    font-weight: 600;
    color: #645c7e;
}

section.product-detail .prod-links .nav-pills {
    width: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

@media (max-width: 576px) {
    section.product-detail .prod-links .nav-pills {
        -ms-flex-wrap: inherit;
        flex-wrap: inherit;
        overflow: auto;
        -webkit-box-pack: flex-start;
        -ms-flex-pack: flex-start;
        justify-content: flex-flex-start;
    }
}

section.product-detail .prod-links .nav-pills button, section.product-detail .prod-links .nav-pills a {
    text-decoration: none !important;
    font-size: 15px;
    border: 1px solid #d5cbde;
    border-radius: 3px;
    padding: 13px 0px;
    color: #645c7e;
}

@media (max-width: 576px) {
    section.product-detail .prod-links .nav-pills button,
    section.product-detail .prod-links .nav-pills a {
        border: none;
        padding: 13px 15px;
    }
    section.product-detail .prod-links .nav-pills button:first-child,
    section.product-detail .prod-links .nav-pills a:first-child {
        padding-left: 0;
        margin-left: 45px !important;
    }
}

@media (min-width: 577px) {
    section.product-detail .prod-links .nav-pills button,
    section.product-detail .prod-links .nav-pills a {
        max-width: 150px;
        margin-right: 20px;
    }
}

@media (min-width: 1200px) {
    section.product-detail .prod-links .nav-pills button ,
    section.product-detail .prod-links .nav-pills a {
        max-width: 200px;
    }
}

section.product-detail .prod-links .nav-pills button:hover,
section.product-detail .prod-links .nav-pills button.active,
section.product-detail .prod-links .nav-pills a:hover,
section.product-detail .prod-links .nav-pills a.active {
    color: #ffffff;
    background: #3774e8;
}

@media (max-width: 576px) {
    section.product-detail .prod-links .nav-pills button:hover,
    section.product-detail .prod-links .nav-pills button.active,
    section.product-detail .prod-links .nav-pills a:hover,
    section.product-detail .prod-links .nav-pills a.active {
        color: #ee7435;
        background: transparent;
        border-bottom: 1px solid #ee7435;
        border-radius: 0;
    }
}

section.product-detail .service img {
    height: 55px;
}

section.prominent_mi_reports {
    padding: 75px 0;
}

@media (max-width: 576px) {
    section.prominent_mi_reports {
        padding: 40px 0;
    }
}

section.prominent_mi_reports .heading h2 {
    color: #111111;
    font-size: 25px;
    line-height: 34px;
}

@media (max-width: 576px) {
    section.prominent_mi_reports .heading h2 {
        margin-bottom: 25px;
    }
}

section.prominent_mi_reports .mireports_tabs {
    margin-top: 50px;
}

@media (max-width: 576px) {
    section.prominent_mi_reports .mireports_tabs {
        margin-top: 0;
    }
}

section.prominent_mi_reports .mireports_tabs .tabcontent#inventory {
    display: block;
}

@media (max-width: 576px) {
    section.prominent_mi_reports .mireports_tabs .tabcontent#inventory {
        display: none!important;
    }
}

section.prominent_mi_reports .mireports_tabs .tabcontent#inventory .inventory_card {
    padding: 25px 25px;
}

section.prominent_mi_reports .mireports_tabs .tabcontent .inventory_card {
    border: 1px solid #ee7435;
    background-color: #ee7435;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-radius: 6px;
    padding: 10px 25px;
    height: 110px;
}

section.prominent_mi_reports .mireports_tabs .tabcontent .inventory_card h3 {
    margin-bottom: 0;
    color: #fff;
    font-size: 18px;
    line-height: 24px;
}

/* section.prominent_mi_reports .mireports_tabs .tabcontent .inventory_card img {
    height: 100%;
} */

section.prominent_mi_reports .mireports_tabs .tabcontent .report_graph_img {
    margin-top: 30px;
}

section.prominent_mi_reports .mireports_tabs .tabcontent .report_graph_img img {
    width: 100%;
}

section.prominent_mi_reports .mireports_tabs .tab {
    float: left;
    width: 25%;
}

@media (max-width: 576px) {
    section.prominent_mi_reports .mireports_tabs .tab {
        /* width: 100%;
        float: none;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        overflow: auto;
        margin-bottom: 25px; */
        display: none!important;
    }
}

section.prominent_mi_reports .mireports_tabs .tab button {
    display: block;
    background-color: inherit;
    padding: 22px 16px;
    width: 100%;
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    font-size: 15px;
    color: #333333;
    border-bottom: 1px solid #cacaca;
}

@media (max-width: 576px) {
    section.prominent_mi_reports .mireports_tabs .tab button {
        padding: 0px 20px 15px 0;
        display: block;
        word-break: keep-all;
        white-space: nowrap;
    }
}

section.prominent_mi_reports .mireports_tabs .tab button.active {
    font-weight: bold;
}

section.prominent_mi_reports .mireports_tabs .tabcontent {
    float: left;
    width: 75%;
    border-left: none;
    /* display: none; */
    overflow: auto;
}

@media (min-width: 577px) {
    section.prominent_mi_reports .mireports_tabs .tabcontent {
        padding: 0px 0px 0 50px;
    }
}

@media (min-width: 1200px) {
    section.prominent_mi_reports .mireports_tabs .tabcontent {
        padding: 0px 60px;
    }
}

@media (max-width: 576px) {
    section.prominent_mi_reports .mireports_tabs .tabcontent {
        padding: 0px 0px;
        width: 100%;
    }
}

section.prominent_mi_reports .mireports_tabs .tabcontent ul li {
    color: #333333;
    font-size: 14px;
    line-height: 25px;
    margin-bottom: 20px;
}

section.prominent_mi_reports .mireports_tabs .tabcontent ul li p {
    margin-bottom: 0;
}

section.prominent_mi_reports .mireports_tabs .tabcontent ul li p:first-child {
    margin-bottom: 5px;
    color: #000;
    font-weight: 600;
}


/* collapse */

@media (min-width: 576px) {
    section.prominent_mi_reports .mireports_tabs_collapse {
        display: none!important;
    }
}

section.prominent_mi_reports .mireports_tabs_collapse .tabcontent#inventory {
    display: block;
}

section.prominent_mi_reports .mireports_tabs_collapse .tabcontent#inventory .inventory_card {
    padding: 25px 25px;
}

section.prominent_mi_reports .mireports_tabs_collapse .tabcontent .inventory_card {
    border: 1px solid #ee7435;
    background-color: #ee7435;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-radius: 6px;
    padding: 10px 25px;
    height: 110px;
}

section.prominent_mi_reports .mireports_tabs_collapse .tabcontent .inventory_card h3 {
    margin-bottom: 0;
    color: #fff;
    font-size: 18px;
    line-height: 24px;
}

section.prominent_mi_reports .mireports_tabs_collapse .tabcontent .inventory_card img {
    height: 100%;
}

section.prominent_mi_reports .mireports_tabs_collapse .tabcontent .report_graph_img {
    margin-top: 30px;
}

section.prominent_mi_reports .mireports_tabs_collapse .tabcontent .report_graph_img img {
    width: 100%;
}

section.prominent_mi_reports .mireports_tabs_collapse .tab {
    float: left;
    width: 25%;
}

@media (max-width: 576px) {
    section.prominent_mi_reports .mireports_tabs_collapse .tab {
        width: 100%;
        float: none;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        overflow: auto;
        margin-bottom: 25px;
    }
}

section.prominent_mi_reports .mireports_tabs_collapse .tab button {
    display: block;
    background-color: inherit;
    padding: 22px 16px;
    width: 100%;
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    font-size: 15px;
    color: #333333;
    border-bottom: 1px solid #cacaca;
}

@media (max-width: 576px) {
    section.prominent_mi_reports .mireports_tabs_collapse .tab button {
        padding: 0px 20px 15px 0;
        display: block;
        word-break: keep-all;
        white-space: nowrap;
    }
}

section.prominent_mi_reports .mireports_tabs_collapse .tab button.active {
    font-weight: bold;
}

section.prominent_mi_reports .mireports_tabs_collapse .tabcontent {
    /* float: left; */
    width: 75%;
    border-left: none;
    overflow: auto;
}

@media (min-width: 577px) {
    section.prominent_mi_reports .mireports_tabs_collapse .tabcontent {
        padding: 0px 0px 0 50px;
    }
}

@media (min-width: 1200px) {
    section.prominent_mi_reports .mireports_tabs_collapse .tabcontent {
        padding: 0px 60px;
    }
}

@media (max-width: 576px) {
    section.prominent_mi_reports .mireports_tabs_collapse .tabcontent {
        padding: 0px 0px;
        width: 100%;
    }
}

section.prominent_mi_reports .mireports_tabs_collapse .tabcontent ul li {
    color: #333333;
    font-size: 14px;
    line-height: 25px;
    margin-bottom: 20px;
}

section.prominent_mi_reports .mireports_tabs_collapse .tabcontent ul li p {
    margin-bottom: 0;
}

section.prominent_mi_reports .mireports_tabs_collapse .tabcontent ul li p:first-child {
    margin-bottom: 5px;
    color: #000;
    font-weight: 600;
}

.accordion-item:first-of-type,
.accordion-item:last-of-type {
    border-radius: 0px!important;
}

.accordion-item {
    margin-bottom: 1rem!important;
    border: 1px solid rgba(0, 0, 0, .125)!important;
}

.accordion-button {
    font-weight: 600!important;
}

.accordion-button:not(.collapsed) {
    color: #212529!important;
    background-color: #e8e8e8;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

.accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")!important;
    transform: rotate(-180deg)!important;
}


/*  */

.trusted-clients {
    background: #f3f2f6;
    padding: 75px 0;
}

@media (max-width: 576px) {
    .trusted-clients {
        padding: 40px 0;
    }
}

.trusted-clients .trusted-text {
    font-size: 25px;
    color: #222;
    padding: 0px 0 50px 0;
}

@media (max-width: 576px) {
    .trusted-clients .trusted-text {
        margin-bottom: 0;
        padding-bottom: 20px;
    }
}

.trusted-clients .client_logo {
    margin: 40px 0;
    text-align: center;
}


/* About Estater */

section.estater_banner_design {
    background: transparent linear-gradient(90deg, #d1caf3 0%, #ffffff 100%) 0% 0% no-repeat padding-box;
    -webkit-box-shadow: 0px 1px 0px #7e71b1;
    box-shadow: 0px 1px 0px #9484e5;
    margin-top: 65px;
}

@media (max-width: 576px) {
    section.estater_banner_design {
        height: 270px;
    }
}

@media (min-width: 577px) {
    section.estater_banner_design {
        height: 250px;
    }
}

@media (min-width: 1200px) {
    section.estater_banner_design {
        height: 300px;
    }
}

section.estater_banner_design .banner-text {
    max-width: 300px;
}

@media (max-width: 576px) {
    section.estater_banner_design .banner-text {
        max-width: 290px;
        margin-top: 15px;
        
    }
}

section.estater_banner_design .banner-text h1 {
    margin-bottom: 30px;
    color: #664fd9;
    font-size: 22px;
    line-height: 30px;
}

@media (max-width: 576px) {
    section.estater_banner_design .banner-text h1 {
        font-size: 19px;
        margin-bottom: 20px;
    }
}

@media (min-width: 577px) {
    section.estater_banner_design .banner-text h1 {
        max-width: 330px;
    }
}

@media (min-width: 1200px) {
    section.estater_banner_design .banner-text h1 {
        max-width: 600px;
    }
}

section.estater_banner_design .banner-text .request_demo {
    padding: 12px 12px;
    border: 1px solid #644fe8;
    border-radius: 6px;
    font-size: 16px;
    background-color: #fff;
    color: #644fe8;
}

section.estater_banner_design .web_banner {
    position: absolute;
    right: 15px;
    bottom: 0;
}

@media (max-width: 576px) {
    section.estater_banner_design .web_banner {
        width: 50%;
    }
}

@media (min-width: 577px) {
    section.estater_banner_design .web_banner {
        height: calc(100% - 65px);
    }
}

@media (min-width: 1200px) {
    section.estater_banner_design .web_banner {
        /* height: auto; */
    }
}

section.about-estater {
    padding: 75px 0;
}

@media (max-width: 576px) {
    section.about-estater {
        padding: 40px 0;
    }
}

section.about-estater .heading {
    margin-bottom: 20px;
}

section.about-estater .heading h3 {
    margin-bottom: 30px;
    color: #222222;
    font-size: 22px;
    line-height: 30px;
    font-weight: 600;
}

section.about-estater .about_content p {
    line-height: 30px;
    font-size: 15px;
    color: #222222;
    margin-bottom: 30px;
}

@media (max-width: 576px) {
    section.about-estater .about_content p {
        text-align: justify;
    }
}

section.about-estater .approach {
    margin-top: 75px;
}

section.about-estater .approach .header {
    text-align: center;
}

section.about-estater .approach .header h3 {
    font-size: 20px;
    line-height: 27px;
    color: #000000;
}

section.about-estater .approach .banner_image {
    text-align: center;
}

.coverage_database {
    margin: 75px 0;
}

@media (max-width: 576px) {
    .coverage_database {
        margin: 40px 0;
    }
}

.coverage_database .heading {
    margin-bottom: 50px;
}

.coverage_database .heading h2 {
    color: #111111;
    font-size: 25px;
    line-height: 34px;
    text-align: center;
}

.coverage_database .cards {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-shadow: 0px 3px 6px #00000029;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 6px;
    margin-bottom: 30px;
}

.coverage_database .cards .illus_image {
    padding: 10px 25px 0 25px;
}

.coverage_database .cards .illus_image h3 {
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    text-align: center;
}

.coverage_database .cards .illus_image img {
    height: 70px;
}

.coverage_database .cards.card_kuwait {
    background-color: #ee7435;
}

.coverage_database .cards.card_bahrain {
    background-color: #3774eb;
}

.coverage_database .cards.card_dubai {
    background-color: #57ac52;
}

.coverage_database .cards.card_riyadh {
    background-color:#664fd9;
}

.coverage_database .cards .table_content {
    width: 100%;
}

.coverage_database .cards .table_content table {
    width: 100%;
}

.coverage_database .cards .table_content table tr th {
    border: 1px solid #fff;
    text-align: center;
    font-size: 15px;
    line-height: 28px;
    color: #fff;
    font-weight: normal;
    border-top: none;
    padding: 25px 0;
}

.coverage_database .cards .table_content table tr th span {
    font-size: 19px;
    line-height: 28px;
    font-weight: bold;
}

.coverage_database .cards .table_content table tr td {
    border: 1px solid #fff;
    padding: 15px 0;
    text-align: center;
    font-size: 13px;
    line-height: 13px;
    color: #fff;
}

@media (min-width: 577px) {
    .coverage_database .cards .table_content table tr td:last-child {
        border-right: 0;
    }
}

@media (min-width: 1200px) {
    .coverage_database .cards .table_content table tr td:last-child {
        border-right: 0;
    }
}

.coverage_database .cards .table_content table tr:last-child td {
    border-bottom: 0;
}

@media (min-width: 577px) {
    .pr_m {
        padding-right: 0;
    }
}

@media (min-width: 992px) {
    .pr_m {
        padding-right: 15px;
    }
}

.about_overview section.estater_services {
    background-color: #f3fbfd;
    padding: 75px 0 75px 0;
}

@media (max-width: 576px) {
    .about_overview section.estater_services {
        padding: 40px 0;
    }
}

.about_overview section.estater_services .heading {
    margin-bottom: 50px;
   
}
.about_overview section.estater_services .heading h3 {
  font-size: 1.5rem;
   
}

.about_overview section.estater_services .heading h2 {
    margin-bottom: 30px;
    color: #111111;
    font-size: 25px;
    line-height: 34px;
}

.about_overview section.estater_services .service_cards {
    text-align: center;
    background-color: #fff;
    -webkit-box-shadow: 0px 3px 6px #002c3624;
    box-shadow: 0px 3px 6px #002c3624;
    border-radius: 6px;
    margin-bottom: 40px;
}

@media (max-width: 576px) {
    .about_overview section.estater_services .service_cards {
        padding: 30px 20px;
    }
}

@media (min-width: 577px) {
    .about_overview section.estater_services .service_cards {
        padding: 30px 20px;
    }
}

@media (min-width: 1200px) {
    .about_overview section.estater_services .service_cards {
        padding: 40px 85px;
    }
}

.about_overview section.estater_services .service_cards h3 {
    font-size: 25px;
    color: #222;
}

@media (min-width: 577px) {
    .about_overview section.estater_services .service_cards h3 {
        margin: 10px 0 20px 0;
    }
}

@media (min-width: 1200px) {
    .about_overview section.estater_services .service_cards h3 {
        margin: 0px 0 25px 0;
    }
}

.about_overview section.estater_services .service_cards p {
    line-height: 25px;
    font-size: 14px;
    color: #333;
    text-align-last: center;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

@media (max-width: 576px) {
    .about_overview section.estater_services .service_cards p {
        text-align: justify;
    }
}

.about_overview section.estater_services .service_cards a {
    margin-top: 25px;
    font-size: 15px;
    color: #666666;
    line-height: 11px;
}

.about_overview section.estater_services .service_cards a:hover {
    border-bottom: 1px solid #666666;
    padding-bottom: 0px;
}

.mb_100 {
    margin-bottom: 100px;
}

@media (max-width: 576px) {
    .mb_100 {
        margin-bottom: 50px;
    }
}

.about_what_we_do section.strategy_involves {
    margin: 75px 0;
}

@media (max-width: 576px) {
    .about_what_we_do section.strategy_involves {
        margin: 40px 0;
    }
}

.about_what_we_do section.strategy_involves .heading {
    margin-bottom: 60px;
}

.about_what_we_do section.strategy_involves .heading h2 {
    color: #111111;
    font-size: 25px;
    line-height: 34px;
}

.about_what_we_do section.strategy_involves .segments {
    margin-bottom: 75px;
}

@media (max-width: 576px) {
    .about_what_we_do section.strategy_involves .segments {
        margin-bottom: 40px;
    }
}

.about_what_we_do section.strategy_involves .segments .heading {
    margin-bottom: 25px;
}

.about_what_we_do section.strategy_involves .segments .heading h3 {
    font-size: 18px;
    line-height: 22px;
    color: #664fd9;
    display: inline-block;
    position: relative;
}

.about_what_we_do section.strategy_involves .segments .heading h3::before {
    position: absolute;
    width: 50px;
    height: 3px;
    background-color: #ee7435;
    bottom: -10px;
    left: 0;
    content: "";
}

.about_what_we_do section.strategy_involves .segments .content p {
    font-size: 15px;
    line-height: 25px;
    color: #333333;
    margin-bottom: 0;
}

.about_what_we_do section.estater_services {
    background-color: #c3d5f8;
    padding: 75px 0 75px 0;
}

@media (max-width: 576px) {
    .about_what_we_do section.estater_services {
        padding: 40px 0;
    }
}

.about_what_we_do section.estater_services .heading {
    margin-bottom: 50px;
}

@media (max-width: 576px) {
    .about_what_we_do section.estater_services .heading {
        margin-bottom: 30px;
    }
}

.about_what_we_do section.estater_services .heading h2 {
    margin-bottom: 30px;
    color: #111111;
    font-size: 25px;
    line-height: 34px;
}

@media (min-width: 577px) {
    .about_what_we_do section.estater_services .heading-p {
        text-align: left;
    }
}

@media (min-width: 1200px) {
    .about_what_we_do section.estater_services .heading-p {
        text-align: right;
    }
}

.about_what_we_do section.estater_services .heading-p p {
    font-size: 14px;
    line-height: 25px;
    color: #222;
}

@media (min-width: 577px) {
    .about_what_we_do section.estater_services .heading-p p {
        text-align: justify;
    }
}

@media (min-width: 1200px) {
    .about_what_we_do section.estater_services .heading-p p {
        text-align: right;
    }
}

.about_what_we_do section.estater_services .service_cards {
    padding: 40px 30px;
    text-align: center;
    -webkit-box-shadow: 0px 1px 3px #87acf1;
    box-shadow: 0px 1px 3px #87acf1;
    border: 1px solid #b5c8cd;
    border-radius: 6px;
    background: transparent;
    margin-bottom: 40px;
    background-color: #f3fbfd;
}

@media (max-width: 576px) {
    .about_what_we_do section.estater_services .service_cards {
        padding: 20px 20px;
    }
}

@media (min-width: 577px) {
    .about_what_we_do section.estater_services .service_cards {
        padding: 40px 20px 20px 20px;
        min-height: 450px;
    }
}

@media (min-width: 992px) {
    .about_what_we_do section.estater_services .service_cards {
        min-height: 475px;
    }
}

@media (min-width: 1400px) {
    .about_what_we_do section.estater_services .service_cards {
        padding: 40px 30px;
        min-height: 415px;
    }
}

.about_what_we_do section.estater_services .service_cards h3 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 11px;
    color: #664fd9;
    margin: 25px 0;
}

.about_what_we_do section.estater_services .service_cards p {
    line-height: 25px;
    font-size: 14px;
    color: #333;
    text-align-last: center;
}

.about_what_we_do section.estater_services .service_cards a {
    margin-top: 25px;
    font-size: 15px;
    color: #666666;
    line-height: 11px;
}

.about_what_we_do section.estater_services .service_cards a:hover {
    border-bottom: 1px solid #666666;
    padding-bottom: 0px;
}

.case_studies .case_studies {
    margin: 75px 0;
}

@media (max-width: 576px) {
    .case_studies .case_studies {
        margin: 40px 0;
    }
}

@media (max-width: 576px) {
    .case_studies .case_studies .col_reverse {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
    }
}

.case_studies .case_studies .casestudy_heading h2 {
    font-size: 25px;
    line-height: 36px;
    color: #000;
    margin-bottom: 25px;
}

.case_studies .case_studies .casestudy_heading p {
    font-size: 15px;
    line-height: 24px;
    color: #333;
}

@media (max-width: 576px) {
    .case_studies .case_studies .casestudy_heading p {
        text-align: justify;
    }
}

.case_studies .case_studies .casestudy_solution {
    margin-top: 15px;
}

.case_studies .case_studies .casestudy_solution h5 {
    font-size: 15px;
    line-height: 24px;
    color: #555;
    font-weight: bold;
    margin-bottom: 25px;
}

.case_studies .case_studies .casestudy_solution p {
    font-size: 15px;
    line-height: 24px;
    color: #333;
}

@media (max-width: 576px) {
    .case_studies .case_studies .casestudy_solution p {
        text-align: justify;
    }
}

.case_studies .case_studies .casestudy_solution ul.casestudy_points {
    margin-top: 25px;
}

.case_studies .case_studies .casestudy_solution ul.casestudy_points li p {
    position: relative;
    padding-left: 20px;
}

@media (max-width: 576px) {
    .case_studies .case_studies .casestudy_solution ul.casestudy_points li p {
        text-align: justify;
    }
}

.case_studies .case_studies .casestudy_solution ul.casestudy_points li p::before {
    height: 7px;
    width: 7px;
    background-color: #664fd9;
    border-radius: 50%;
    top: 8px;
    left: 0;
    position: absolute;
    content: "";
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.case_studies .case_studies .casestudy_img {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%;
}

@media (max-width: 576px) {
    .case_studies .case_studies .casestudy_img {
        margin-top: 15px;
        margin-bottom: 25px;
    }
}

.geo_estater section.geoestater {
    padding: 75px 0 0 0;
}

@media (max-width: 576px) {
    .geo_estater section.geoestater {
        padding: 40px 0;
    }
}

.geo_estater section.geoestater .main_heading {
    margin-bottom: 50px;
}

.geo_estater section.geoestater .main_heading .heading h2 {
    font-size: 25px;
    line-height: 34px;
    color: #000;
    margin-bottom: 25px;
}

.geo_estater section.geoestater .main_heading .heading p {
    font-size: 15px;
    line-height: 30px;
    color: #333;
}

@media (max-width: 576px) {
    .geo_estater section.geoestater .main_heading .heading p {
        text-align: justify;
    }
}

.geo_estater section.geoestater .estater_data {
    padding: 100px 0;
}

@media (max-width: 576px) {
    .geo_estater section.geoestater .estater_data {
        padding: 50px 0;
    }
}

.geo_estater section.geoestater .estater_data .content {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: justify;
}

@media (min-width: 577px) {
    .geo_estater section.geoestater .estater_data .content {
        max-width: 425px;
    }
}

@media (min-width: 992px) {
    .geo_estater section.geoestater .estater_data .content {
        max-width: inherit;
    }
}

.geo_estater section.geoestater .estater_data .content h3 {
    font-size: 25px;
    line-height: 34px;
    color: #fff;
    margin-bottom: 25px;
}

.geo_estater section.geoestater .estater_data .content p {
    font-size: 16px;
    line-height: 30px;
    color: #fff;
    margin-bottom: 0;
}

@media (max-width: 576px) {
    .geo_estater section.geoestater .estater_data .content p {
        text-align: justify;
    }
}

.geo_estater section.geoestater .estater_data .img {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

@media (max-width: 576px) {
    .geo_estater section.geoestater .estater_data .img {
        margin-top: 25px;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}

@media (max-width: 576px) {
    .geo_estater section.geoestater .estater_data .img img {
        width: 50%;
    }
}

.geo_estater section.geoestater .estater_data.data_collection {
    background-color: #ee7435;
}

.geo_estater section.geoestater .estater_data.cleansing {
    background-color: #3774e8;
}

.geo_estater section.geoestater .estater_data.locationalytics {
    background-color: #57ac52;
}

.about_who_we_are section.our_team {
    padding: 75px 0 100px 0;
}

@media (max-width: 576px) {
    .about_who_we_are section.our_team {
        padding: 40px 0;
    }
}

.about_who_we_are section.our_team .heading {
    margin-bottom: 75px;
}

@media (max-width: 576px) {
    .about_who_we_are section.our_team .heading {
        margin-bottom: 40px;
    }
}

.about_who_we_are section.our_team .heading h2 {
    margin-bottom: 30px;
    color: #000;
    font-size: 25px;
    line-height: 39px;
    text-align: center;
}

@media (min-width: 577px) {
    .about_who_we_are section.our_team .team_member {
        padding: 0px 15px;
    }
}

@media (min-width: 1200px) {
    .about_who_we_are section.our_team .team_member {
        padding: 0px 35px;
    }
}

.about_who_we_are section.our_team .team_member .member_img {
    text-align: center;
}

.about_who_we_are section.our_team .team_member .member_img img {
    margin-bottom: 25px;
}

.about_who_we_are section.our_team .team_member h3 {
    font-size: 18px;
    line-height: 22px;
    color: #444;
    text-align: center;
    margin-bottom: 25px;
}

.about_who_we_are section.our_team .team_member p {
    font-size: 13px;
    line-height: 22px;
    color: #777;
}

@media (max-width: 576px) {
    .about_who_we_are section.our_team .team_member p {
        text-align: justify;
        font-size: 15px;
        text-align-last: center;
    }
}

.about_who_we_are section.our_team .social_links {
    padding: 0 35px;
}

@media (max-width: 576px) {
    .about_who_we_are section.our_team .social_links {
        margin-bottom: 50px;
    }
}

.about_who_we_are section.our_team .social_links ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-top: 1px solid #bababa;
    padding-top: 30px;
    margin-top: 30px;
}

.about_who_we_are section.our_team .social_links ul li {
    padding: 0 15px;
}

.about_who_we_are section.our_team .social_links ul li a {
    color: #444444;
    font-size: 18px;
}

.about_who_we_are section.our_team .social_links ul li a:hover {
    color: #6449DB;
}

.banner_image_ar {
    display: none;
}

.maintain-text {
    font-size: 17px;
    line-height: 35px;
    padding: 10px 0px 40px 0px;
    color: #444444;
    font-weight: 400;
    margin: 0 auto;
}

@media (min-width: 577px) {
    .maintain-text {
        width: 90%;
    }
}

@media (min-width: 992px) {
    .maintain-text {
        width: 60%;
    }
}

.h-70p {
    height: 70px;
}

.service {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 20px 0px 7% 0px;
}

@media (min-width: 992px) {
    .service {
        margin: 20px 0px 5% 0px;
    }
}

.service-name {
    color: #ee7435;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    line-height: 25px;
}

.service-detail {
    font-size: 15px;
    color: #444444;
    font-weight: 400;
    line-height: 22px;
}

@media (min-width: 577px) {
    .service-detail {
        margin: 0px 0%;
    }
}

@media (min-width: 1200px) {
    .service-detail {
        margin: 0px 14%;
    }
}

.h_50p {
    height: 50px !important;
}

.pro_banner_ar {
    display: none;
}

@media screen and (min-width: 1600px) {
    .maintain-text {
        font-size: 16px;
    }
}

@media screen and (max-width: 767px) {
    .maintain-text {
        font-size: 15px;
        line-height: 30px !important;
        padding: 0px 0px 10px 0px !important;
        width: 95%;
        text-align: justify !important;
        text-align-last: center;
    }
    .service-detail {
        line-height: 25px;
    }
}

@media screen and (max-width: 767px) {
    .trusted-clients {
        padding: 40px 0px 70px 0px;
    }
    .trusted-clients .client_logo {
        margin: 25px 0;
    }
    .trusted-text {
        margin: 0px 0px 40px 0;
    }
    .leading-text {
        padding: 50px 0 30px 0;
        font-size: 20px;
    }
    .prod-links {
        border-bottom: 1px solid #bbb;
        padding-bottom: 0;
        overflow-x: overlay;
        overflow-y: hidden;
        -webkit-box-pack: inherit;
        -ms-flex-pack: inherit;
        justify-content: inherit;
        margin-bottom: 35px;
    }
    .prod-links button {
        border: none;
        padding: 13px 17px;
    }
}


/* Extra Small devices (Extra small phones, up to 599px) */

@media (max-width: 599px) {
    .intro {
        height: 250px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        position: relative;
        margin: 100px 15px 0 15px;
    }
    .intro .banner_image {
        position: absolute;
        right: 0px;
        bottom: 0;
    }
    .banner-text span {
        max-width: 300px;
        display: inline-block;
    }
    .view-product {
        display: none;
    }
}


/* Small devices (landscape phones, 600px and up) */

@media (min-width: 600px) {
    .intro {
        height: 225px;
        position: relative;
    }
    .intro .banner_image {
        height: calc(100% - 50px);
        position: absolute;
        right: 25px;
        bottom: 0;
    }
    .banner-text {
        font-size: 19px;
        max-width: 400px;
    }
}


/* Medium devices (tablets, 768px and up) */

@media (min-width: 768px) {
    .intro {
        height: 250px;
    }
}


/* Large devices (desktops, 992px and up) */

@media (min-width: 992px) {
    .intro {
        height: 300px;
    }
    .banner-text {
        font-size: 22px;
        max-width: 500px;
    }
}


/* Extra large devices (large desktops, 1200px and up) */

@media (min-width: 1200px) {
    .intro {
        height: 350px;
        padding: 0 15px;
    }
    .banner-text {
        max-width: 600px;
    }
}

@media (max-width: 575.98px) {
    .navbar-toggle {
        display: block;
    }
}

.slider {
    width: 100%;
    margin: 0px auto;
}

.slick-active {
    opacity: 1 !important;
}

.slick-slide {
    margin: 0px 40px 10px 0;
    height: 250px;
}

@media (max-width: 992px) {
    .slick-slide {
        height: auto;
        margin: 0px 15px 0px 0px;
    }
    .properties_list_slider .slick-slide {
        margin: 0px;
    }
}

.slick-list {
    margin: 0px -40px 0 0;
    height: auto !important;
}

@media (max-width: 576px) {
    .slick-list {
        height: auto !important;
        margin: 0px 0px 0 0px;
    }
}

.slick-slide img {
    width: 100%;
}

.slick-prev:before,
.slick-next:before {
    color: black !important;
    position:absolute !important;
    top:-220px;

}

.slick-arrow {
    cursor: pointer;
}

.slick-arrow:hover {
    color: gray !important;
}

.popup_request_demo {
    padding: 35px 75px;
    position: relative;
    max-width: 600px;
}

.popup_request_demo .popup_close {
    position: absolute;
    top: 25px;
    right: 25px;
    font-size: 40px;
    color: #333333;
}

.popup_request_demo .heading h2 {
    color: #000000;
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 21px;
}

.popup_request_demo .heading p {
    font-size: 16px;
    line-height: 25px;
    color: #666666;
}

.popup_request_demo .form {
    margin-top: 30px;
}

.popup_request_demo .form .form-group {
    margin-bottom: 15px;
}

.popup_request_demo .form .form-group.label_check {
    margin-top: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.popup_request_demo .form .form-group.label_check .checkbox-inline {
    font-size: 12px;
    line-height: 15px;
    color: #888;
    padding-left: 10px;
}

.popup_request_demo .form .form-group.label_check .checkbox-inline span {
    margin-top: 15px;
    display: inline-block;
}

.popup_request_demo .form .form-group.label_check .checkbox-inline span:first-child {
    margin-top: 0;
}

.popup_request_demo .form .form-group .btn_submit_new {
    margin-top: 20px;
    height: 41px;
    padding: 0 70px;
    background-color: #644fe8;
    font-size: 15px;
    line-height: 20px;
    color: #fff;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border: 1px solid #644fe8;
}

.popup_request_demo .form .form-group .btn_submit_new:hover {
    background-color: #52207d;
}

.popup_request_demo .form input.form_field {
    border: none;
    border-bottom: 1px solid #d4d4d4;
    font-size: 15px;
    line-height: 20px;
    color: #888;
    border-radius: 0;
}

.popup_request_demo .close-modal {
    display: none !important;
}

.show {
    display: block;
}

@media (max-width: 576px) {
    .slick-dots {
        display: block;
        /* bottom:0px !important; */
        margin-bottom: 0px !important;
        font-size: 50px;
    }

    .slick-dots li button:before
    {
        font-family: 'slick';
        font-size: 16px !important;
        line-height: 20px !important;
    }
    /* .slick-dotted.slick-slider {
         margin-bottom: 0px !important;
    } */
}

.slick-dots li {
    margin: 0;
}

.slick-dots li button:before {
    font-size: 27px;
}

.slick-dots li.slick-active button:before {
    color: #444;
}

.footer_stick_link {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 99;
    display: none;
}

@media (max-width: 576px) {
    .footer_stick_link {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
}

.footer_stick_link .request_demo {
    width: 50%;
    margin: 0 !important;
    border-radius: 0;
    text-align: center;
    padding: 0;
    height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.footer_stick_link .client_login {
    width: 50%;
    border-radius: 0;
    text-align: center;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.contact_form .col_challenges {
    background-color: #fff !important;
    height: auto !important;
}

.contact_form .col_challenges .contact_address form .input_field {
    width: 100%;
    height: 45px;
    border: none;
    border-bottom: 1px solid #bbb;
    color: #666666;
    font-size: 16px;
    margin-bottom: 5px;
    margin-top: 10px;
}
.contact_form .col_challenges .contact_address form .text_field {
    width: 100%;
    min-height: 90px;
    max-height: 150px;
    border: none;
    border-bottom: 1px solid #bbb;
    color: #888888;
    font-size: 16px;
    margin-bottom: 5px;
    margin-top: 10px;
}
@media (max-width: 576px) {
    .contact_form .col_challenges .contact_address form .input_field {
        margin-bottom: 0;
    }
    .contact_form .col_challenges .contact_address form .text_field {
        margin-bottom: 0;
    }
}

.contact_form .col_challenges .contact_address form .btn_submit_new {
    margin-top: 50px;
}

.contact_form .col_challenges .contact_address form .btn_submit_new button {
    background-color: #664fd9;
    border: none;
    padding: 10px 40px;
    color: #fff;
    font-size: 16px;
    border-radius: 5px;
}

.contact_form .col_challenges.news_letter {
    margin-top: 40px;
    padding: 50px 0 !important;
}

@media (max-width: 576px) {
    .contact_form .col_challenges.news_letter {
        margin-top: 0;
        padding: 30px 0;
    }
}

.contact_form .col_challenges.news_letter .newletter_container h3 {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #333333;
    margin-bottom: 15px;
}

.contact_form .col_challenges.news_letter .newletter_container p {
    font-size: 17px;
    letter-spacing: 0px;
    color: #333333;
    margin-bottom: 10px;
    max-width: 500px;
}

@media (max-width: 576px) {
    .contact_form .col_challenges.news_letter .newletter_container .subs-info {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
}

.contact_form .col_challenges.news_letter .newletter_container input {
    color: #666666;
    height: 45px;
    border: none;
    width: 100%;
    font-size: 15px;
    padding: 0 15px;
}

@media (max-width: 576px) {
    .contact_form .col_challenges.news_letter .newletter_container .btn_subscribe {
        margin-top: 20px;
        width: 100%;
    }
}

.contact_form .col_challenges.news_letter .newletter_container .btn_subscribe button {
    background-color: #664fd9;
    color: #fff;
    border-radius: 0;
    height: 45px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    width: 140px;
}

@media (max-width: 576px) {
    .contact_form .col_challenges.news_letter .newletter_container .btn_subscribe button {
        width: 100%;
        border-radius: 4px;
    }
}

@media (max-width: 576px) {
    .contact_form .col_challenges .cont_chll_benfit {
        padding-right: 15px;
    }
}

@media (min-width: 577px) {
    .contact_form .col_challenges .cont_chll_benfit {
        padding-right: 50px;
    }
}

.contact_form .col_benefits {
    background-color: #c3d5f8 !important;
}

.contact_form .col_benefits .contact_address .info_details,
.contact_form .col_benefits .contact_address .info-details.email,
.contact_form .col_benefits .contact_address .location,
.contact_form .col_benefits .contact_address .follow_us {
    display: flex;
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
    column-gap: 20px;
}
.contact_address .location li.address{
    position: relative;
}
.contact_address .location li.address:before {
    content: '';
    position: absolute;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background: #ee7435;
    left: -25px;
    top: 9px;
}
.contact_form .col_benefits .contact_address .info-details.email{
    align-items: center;
}
@media (max-width:767px){
    .contact_form .col_benefits .contact_address .info-details.email{
        margin: 30px 0 45px;
        align-items: center;
    }
}
.contact_form .col_benefits .contact_address .info_details .number {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    font-size: 20px;
    color: #111;
    line-height: 35px;
    font-weight: 600;
}
@media (max-width: 576px) {
    .contact_form .col_benefits .contact_address .info-details img,
    .contact_form .col_benefits .contact_address .info_details img,
    .contact_form .col_benefits .contact_address .location img,
    .contact_form .col_benefits .contact_address .follow_us img {
        margin-right: 25px;
    }
}

@media (min-width: 577px) {
    .contact_form .col_benefits .contact_address .info-details img,
    .contact_form .col_benefits .contact_address .info_details img,
    .contact_form .col_benefits .contact_address .location img,
    .contact_form .col_benefits .contact_address .follow_us img {
        margin-right: 25px;
    }
}

.contact_form .col_benefits .contact_address .info-details span,
.contact_form .col_benefits .contact_address .location .country {
    font-size: 18px;
    color: #111;
    font-weight: 600;
}

@media (min-width: 577px) {
    .contact_form .col_benefits .contact_address .info-details.email {
        padding: 0 0 20px 0;
    }
}

@media (min-width: 992px) {
    .contact_form .col_benefits .contact_address .info-details.email {
        padding: 0 0 55px 0;
    }
}

@media (min-width: 577px) {
    .contact_form .col_benefits .contact_address .call {
        padding: 0 0 20px 0;
    }
}

@media (min-width: 992px) {
    .contact_form .col_benefits .contact_address .call {
        padding: 0 0 55px 0;
    }
}

.contact_form .col_benefits .contact_address .all {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
}

@media (min-width: 577px) {
    .contact_form .col_benefits .contact_address .location {
        padding-bottom: 20px;
    }
}

@media (min-width: 992px) {
    .contact_form .col_benefits .contact_address .location {
        padding-bottom: 55px;
    }
}

.contact_form .col_benefits .contact_address .location .address {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    line-height: 25px;
    font-size: 14px;
    color: #111;
    max-width: 440px;
}
.contact_form .col_benefits .contact_address .location .address b{
    color: #676565;
}
.contact_form .col_benefits .contact_address .location .address span{
    color: #644fe8;
    font-weight: 600;
    display: block;
}

.contact_form .col_benefits .contact_address .follow_us h2 {
    font-size: 18px;
    color: #111;
    font-weight: 600;
}

.contact_form .col_benefits .contact_address .follow_us .follow-text {
    max-width: 350px;
}

.contact_form .col_benefits .contact_address .follow_us .follow_socialmedia {
    padding-left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 20px;
}

.contact_form .col_benefits .contact_address .follow_us .follow_socialmedia li {
    list-style: none;
    padding: 0 0;
    display: inline-block;
    text-align: center;
}

.contact_form .col_benefits .contact_address .follow_us .follow_socialmedia li a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 45px;
    width: 45px;
    background-color: #fff;
    border-radius: 50%;
    margin: 0 30px;
}
@media (max-width: 768px) {
    .contact_form .col_benefits .contact_address .follow_us .follow_socialmedia li a {
        margin: 0 10px;
        height: 40px;
        width: 40px;
    }
    
}


.contact_form .col_benefits .contact_address .follow_us .follow_socialmedia li a:first-child {
    margin-left: 0;
}

 .contact_form .col_benefits .contact_address .follow_us .follow_socialmedia li a .mat-icon{
    color: #644fe8;
    font-size: 18px;
}

.contact_form .col_benefits .contact_address .follow_us .follow_socialmedia li a:hover {
    text-decoration: none;
    background-color: #644fe8;
}

.contact_form .col_benefits .contact_address .follow_us .follow_socialmedia li a:hover .mat-icon {
    color: #fff;
}

@media (max-width: 576px) {
    .contact_form .col_benefits .cont_chll_benfit {
        padding-left: 15px !important;
    }
}

@media (min-width: 577px) {
    .contact_form .col_benefits .cont_chll_benfit {
        padding-left: 30px !important;
    }
}

@media (min-width: 1200px) {
    .contact_form .col_benefits .cont_chll_benfit {
        padding-left: 50px !important;
    }
}


/* width */

::-webkit-scrollbar {
    width: 8px;
}

@media (max-width: 576px) {
     ::-webkit-scrollbar {
        width: 4px;
        height: 1px;
    }
}


/* Track */

::-webkit-scrollbar-track {
    background: #f1f1f1;
}


/* Handle */

::-webkit-scrollbar-thumb {
    background: #888;
}


/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.b-menu {
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.b-bun {
    height: 2px;
    width: 22px;
    background: #644fe8;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.b-bun:nth-child(2) {
    margin-top: 5px;
}

.b-bun:nth-child(3) {
    margin-top: 5px;
}

.privacy_content .textp {
    font-size: 14px;
    font-weight: 500 !important;
    text-align: justify;
    line-height: 1.8;
}

.privacy_content .main_ol {
    padding: 0;
    margin-top: 40px;
}

.privacy_content .main_ol>li {
    margin-bottom: 40px;
    list-style-type: decimal;
    list-style-position: inside;
    font-size: 17px;
    color: #000000;
    font-weight: 600;
}

.privacy_content .main_ol>li>p:first-child {
    margin-bottom: 10px;
    display: inline-block;
    font-size: 17px;
}

.privacy_content .main_ol>li>p .textp {
    color: #212529;
}

.privacy_content .main_ol>li>ol {
    padding: 15px 15px 0 15px;
}

.privacy_content .main_ol>li>ol li {
    list-style-type: lower-alpha;
    list-style-position: inside;
    margin-bottom: 25px;
    font-weight: 500;
}

.privacy_content .main_ol>li>ol li .textp {
    color: #212529;
    margin-top: 10px;
    display: inline-block;
}

.privacy_content .main_ol>li>ol .lower_alpha {
    padding-left: 20px;
    margin-top: 15px;
    word-break: break-word;
}

@media (max-width: 576px) {
    .privacy_content .main_ol>li>ol .lower_alpha {
        padding-left: 0px;
    }
}

.privacy_content .main_ol>li>ol .lower_alpha li {
    list-style: lower-roman;
    list-style-position: inside;
}

.privacy_content ol.mb-2 li {
    line-height: 2;
}

.privacy_content ol.final_inner_list {
    padding-left: 19px !important;
    padding-top: 0 !important;
}

.privacy_content ol.final_inner_list li {
    color: #212529;
    margin-bottom: 0 !important;
    list-style: none !important;
    position: relative;
    z-index: -1;
}

.privacy_content ol.final_inner_list li::before {
    content: "";
    position: absolute;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: #b1b1b1;
    left: -18px;
    top: 17px;
}

.privacy_content .main-heading {
    display: inline-block;
}

.case-study-header {
    background: url("/assets/images/case-study-banner.jpg") no-repeat center top;
    background-size: cover;
    width: 100%;
    color: #fff;
    vertical-align: middle;
    padding: 100px 0px;
    margin-top: 50px;
}
.case-study-header.terms-banner {
    background: url("/assets/images/terms-banner.jpg") no-repeat center top;
    padding: 40px 0px;
}

@media (max-width: 576px) {
    .case-study-header {
        padding: 45px 0px;
    }
}

.case-study-header h1 {
    font-size: 24px;
}

.help_center {
    background-image: url("./../images/faq-background.png");
    height: 135px;
    width: 100%;
    text-align: -webkit-center;
    margin-top: 50px;
    line-height: 100px;
    background-size: cover;
    background-repeat: no-repeat;
}

.help_center .header_bnr {
    width: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    line-height: 100px;
}

@media (max-width: 576px) {
    .help_center .header_bnr {
        line-height: 50px;
    }
}

.help_center .header_bnr .btn_back {
    text-align: left;
}

.help_center .header_bnr .btn_back .mat-icon {
    cursor: pointer;
    font-size: 15px;
}

.help_center .heading h5 {
    color: #333333;
    font-size: 16px;
    text-align: left;
    font-weight: 600;
}

.help_search {
    margin-top: 40px;
}

.help_search .search-input {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.help_search input {
    height: 40px;
    border-radius: 22px;
    background-color: #ffffff;
    border: none;
    outline: none;
    width: 400px;
    padding: 0 40px;
    background-image: url("../images/search_icon.png");
    background-repeat: no-repeat;
    background-size: 17px;
    background-position: left 13px center;
    max-width: 425px;
    border: 1px solid #cfcfcf;
    display: initial;
}

.help_search .text_right {
    text-align: right;
}

.help_search .button-submit-query {
    background-color: #664fd9;
    border-radius: 4px;
    padding: 7px 13px;
    color: #fff;
    text-align: right;
}

.help_search .button-submit-query:hover {
    background-color: #664fd9;
}

.bread_crumb {
    background-color: #eaeaea;
    padding: 8px 0;
}

.bread_crumb h5 {
    font-size: 13px;
    line-height: 18px;
    color: #555555;
    margin-bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.bread_crumb h5 a {
    color: #5c2d83;
}

.bread_crumb.avm_breadcrumb {
    background-color: #fff;
    position:-webkit-fixed;
    position: fixed;
    top: 170px;
    width: 100%;
    z-index: 2;
    border-bottom: 1px solid #d0d5df;
}

.bread_crumb.avm_breadcrumb a {
    color: #ee7435 !important;
}

.help_content_container button {
    background-color: white;
    height: 65px;
    color: #444444;
    border-radius: 6px;
    font-size: 16px;
    outline: none;
    border: 1px solid #a2babe;
    margin-top: 30px;
    width: 100%;
}

.help_content_container button:hover,
.help_content_container button.active {
    color: white;
    background-color: #664fd9;
    border-color: #664fd9;
}

.faq_question {
    margin-top: 40px;
    /* Change background color of buttons on hover */
    /* Create an active/current "tab button" class */
    /* Style the tab content */
}

.faq_question .inner_content {
    display: none;
}

.faq_question .tab {
    float: left;
}

@media (max-width: 576px) {
    .faq_question .tab {
        width: 100%;
    }
}

@media (min-width: 577px) {
    .faq_question .tab {
        width: 30%;
    }
}

@media (min-width: 1200px) {
    .faq_question .tab {
        width: 25%;
    }
}

.faq_question .tab button {
    display: block;
    background-color: transparent;
    color: #333;
    padding: 25px 16px 25px 0;
    width: 100%;
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    border-bottom: 1px solid #ddd;
    font-size: 16px;
}

.faq_question .tab button:first-child {
    padding-top: 0;
}

.faq_question .tab button:hover {
    color: #664fd9;
}

.faq_question .tab button.active {
    color: #664fd9;
}

.faq_question .tabcontent {
    float: left;
    border-left: none;
}

@media (max-width: 576px) {
    .faq_question .tabcontent {
        width: 100%;
    }
}

@media (min-width: 577px) {
    .faq_question .tabcontent {
        width: 70%;
        padding: 0px 0px 0 50px;
    }
}

@media (min-width: 1200px) {
    .faq_question .tabcontent {
        width: 75%;
        padding: 0px 0px 0 90px;
    }
}

.faq_question .tabcontent#home_owners_tab1 {
    display: block;
}

.faq_question .faq_qst h6 {
    margin-bottom: 10px;
    display: inline-block;
    font-size: 22px;
    margin-bottom: 20px;
    color: #333;
}

.faq_question .faq_ans p {
    font-size: 16px;
    font-weight: 500;
    text-align: justify;
    line-height: 1.8;
    margin-bottom: 30px;
    color: #333;
}

.faq_question .faq_ans p.sub-text {
    font-size: 14px;
    margin-bottom: 45px;
}

@media (min-width: 577px) {
    .faq_question .faq_ans p.sub-text {
        max-width: 475px;
    }
}

@media (min-width: 1200px) {
    .faq_question .faq_ans p.sub-text {
        max-width: 100%;
    }
}

.faq_question .faq_ans h6 {
    margin-bottom: 10px;
    display: inline-block;
    font-size: 22px;
    color: #333;
}

.faq_question .faq_ans img {
    border: 1px solid #ddd;
    border-radius: 6px;
    width: 80%;
}

.faq_question .faq_ans ul li {
    font-size: 16px;
    font-weight: 500;
    text-align: justify;
    line-height: 1.8;
    color: #333;
}

.buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.buttons .button-cancel {
    margin-left: 20px;
}

section.query_page {
    margin-top: 50px;
    /* Custom Dropdown */
    /* The container must be positioned relative: */
    /* Style the arrow inside the select element: */
    /* Point the arrow upwards when the select box is open (active): */
    /* style the items (options), including the selected item: */
    /* Style items (options): */
    /* Hide the items when the select box is closed: */
}

section.query_page .text_right {
    text-align: right;
}

section.query_page .form-group {
    margin-bottom: 30px;
}

section.query_page .form-group label {
    color: #333333;
    font-size: 16px;
    font-weight: 600;
}
section.query_page h1{
    font-size: 2rem;
    margin: 0.5rem 1rem 3rem;
}
section.query_page .form-group input,
section.query_page .form-group select {
  height: 45px;
}
section.query_page .form-group textarea {
    min-height: 90px;
    max-height: 150px;
  }
section.query_page .custom-select {
    position: relative;
    padding: 0;
    border-bottom: 1px solod black;
    background: none;

}

section.query_page .custom-select::after {
    content: "\f107";
    font-family: "FontAwesome";
    position: absolute;
    right: 0;
    top: 5px;
    font-size: 22px;
    color: #bbb;
    display:none;
}

section.query_page .custom-select select {
    width: 100%;
    padding: 8px !important;
    font-size: 16px;
    color:#645c7e;

}

section.query_page .custom-select select::placeholder {
    font-size: 22px;
    color: #bbb;
}

section.query_page .select-selected {
    background-color: #fff;
}

section.query_page .select-selected.select-arrow-active:after {
    border-color: transparent transparent #fff transparent;
    top: 7px;
}

section.query_page .select-items div,
section.query_page .select-selected {
    color: #333;
    padding: 8px 16px;
    border: none;
    border-bottom: 1px solid transparent;
    border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
    cursor: pointer;
}

section.query_page .select-items {
    position: absolute;
    background-color: #fff;
    top: 48px;
    left: 0;
    right: 0;
    z-index: 99;
    border: 1px solid #eee;
    border-radius: 4px;
    max-width: 400px;
    -webkit-box-shadow: 2px 2px 4px #eee;
    box-shadow: 2px 2px 4px #eee;
}

section.query_page .select-hide {
    display: none;
}

section.query_page .select-items div:hover,
section.query_page .same-as-selected {
    background-color: rgba(0, 0, 0, 0.1);
}

.button-default {
    border-radius: 6px;
    color: #ffffff;
    background-color: #644fe8;
    width: 250px;
    padding: 13px 0;
}

.button-default:hover {
    color: #644fe8;
    background-color: transparent;
    border: 1px solid #644fe8;
}

.button-cancel {
    -webkit-box-shadow: 0px 5px 6px #0000001c;
    box-shadow: 0px 5px 6px #0000001c;
    border: 1px solid #e3e2e2;
    border-radius: 6px;
    color: #777777;
    width: 250px;
    padding: 13px 0;
}

.button-cancel:hover {
    background-color: #ededed;
    color: #444;
    border-color: #ededed;
}

.btn_wrapper {
    position: relative;
    overflow: hidden;
}

.btn_wrapper .btn {
    height: 45px;
    border: 1px solid #c8c8c8;
    background-color: #fff;
    width: 80%;
    color: #ee7435;
    font-size: 13px;
}

.btn_wrapper .btn span {
    color: #555555;
}

.btn_wrapper input {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

.avm_details {
    background-image: url("./../images/avm-details_banner.png");
    height: 100px;
    width: 100%;
    text-align: -webkit-center;
    margin-top: 0px;
    line-height: 100px;
    background-size: cover;
    background-repeat: no-repeat;
    position: -webkit-fixed;
    position: fixed;
    border-bottom: 2px solid #adadad;
    top: 70px;
    z-index: 2;
}

.avm_details .header_bnr {
    width: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    line-height: 100px;
}

@media (max-width: 576px) {
    .avm_details .header_bnr {
        line-height: 50px;
    }
}

.avm_details .header_bnr .btn_back {
    text-align: left;
}

.avm_details .header_bnr .btn_back .mat-icon{
    cursor: pointer;
    font-size: 15px;
}

.avm_details .heading h5 {
    color: #333333;
    font-size: 16px;
    text-align: left;
    font-weight: 600;
}

.avm_details_tabing {
    margin-top: 59px;
    margin-bottom: 100px;
    /* Style the tab content */
}

.avm_details_tabing .tab {
    float: left;
    position: -webkit-sticky;
    position: sticky;
    top: 290px;
    max-height: 65vh;
    overflow-y: auto;
}

@media (max-width: 576px) {
    .avm_details_tabing .tab {
        width: 50%;
        top: 25%;
        margin-right: 5px;
        margin-top: 160px;       
    }
    .inner_cont{
        display: flex;
    }
}

@media (min-width: 577px) {
    .avm_details_tabing .tab {
        width: 30%;
        margin-top: 160px;
        top: 25%;
    }
}

@media (min-width: 992px) {
    .avm_details_tabing .tab {
        max-height: 100vh;
        top: 25% !important;
        margin-top: 150px;
    }
}

@media (min-width: 1200px) {
    .avm_details_tabing .tab {
        width: 25%;
        max-height: 58vh;
    }
}

@media (min-width: 1400px) {
    .avm_details_tabing .tab {
        width: 25%;
        max-height: 55vh;
        margin-top: 200px;
    }
}

@media (min-width: 1600px) {
    .avm_details_tabing .tab {
        max-height: 65vh;
    }
}

.avm_details_tabing .tab::-webkit-scrollbar {
    width: 2px;
}

.avm_details_tabing .tab::-webkit-scrollbar-thumb {
    background: #999;
}

.avm_details_tabing .tab a {
    display: block;
    background-color: transparent;
    color: #333;
    padding: 25px 16px 25px 0;
    width: 100%;
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    border-bottom: 1px solid #ddd;
    font-size: 16px;
}

.avm_details_tabing .tab a:first-child {
    padding-top: 0;
}

.avm_details_tabing .tab a:hover {
    color: #ee7435;
}

.avm_details_tabing .tab a.active {
    color: #ee7435 !important;
}

.avm_details_tabing .inner_content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.avm_details_tabing .tabcontent {
    float: left;
    border-left: none;
}

@media (max-width: 576px) {
    .avm_details_tabing .tabcontent {
        width: 100%;
        margin-top: 150px;
    }

    .avm_details_tabing .inner_content {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        position: relative;
        top:10%;
    }

    .avm_details_tabing .tab a {
        display: block;
        background-color: transparent;
        color: #333;
        padding: 25px 16px 25px 0;
        width: 110%;
        border: none;
        outline: none;
        text-align: left;
        cursor: pointer;
        -webkit-transition: 0.3s;
        transition: 0.3s;
        border-bottom: 1px solid #ddd;
        font-size: 12px;
        font-weight: bold;
    }
}

@media (min-width: 577px) {
    .avm_details_tabing .tabcontent {
        width: 70%;
        padding: 0px 0px 0 50px;
        margin-top: 160px;
    }
}

@media (min-width: 1200px) {
    .avm_details_tabing .tabcontent {
        width: 75%;
        padding: 0px 0px 0 90px;        
        margin-top:190px;
    }


}

.avm_details_tabing .tabcontent:nth-child(1) {
    display: block !important;
}

.avm_details_tabing .heading h6 {
    margin-bottom: 10px;
    display: inline-block;
    font-size: 22px;
    margin-bottom: 20px;
    color: #333;
}

.avm_details_tabing .content {
    text-align: center;
}

.avm_details_tabing .content p {
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    line-height: 1.8;
    margin-bottom: 30px;
    color: #333;
}

.avm_details_tabing .content p.sub-text {
    font-size: 14px;
    margin-bottom: 45px;
}

@media (min-width: 577px) {
    .avm_details_tabing .content p.sub-text {
        max-width: 475px;
    }
}

@media (min-width: 1200px) {
    .avm_details_tabing .content p.sub-text {
        max-width: 100%;
    }
}

.avm_details_tabing .content h6 {
    margin-bottom: 10px;
    display: inline-block;
    font-size: 22px;
    color: #333;
}

.avm_details_tabing .content ul li {
    font-size: 16px;
    font-weight: 400;
    text-align: justify;
    line-height: 1.8;
    color: #333;
}

.avm_details_tabing .sub_content p {
    font-size: 16px;
    font-weight: 400;
    text-align: justify;
    line-height: 1.8;
    margin-bottom: 0px;
    color: #333;
}

.avm_details_tabing .sub_content p.sub-text {
    font-size: 14px;
    margin-bottom: 45px;
}

@media (min-width: 577px) {
    .avm_details_tabing .sub_content p.sub-text {
        max-width: 475px;
    }
}

@media (min-width: 1200px) {
    .avm_details_tabing .sub_content p.sub-text {
        max-width: 100%;
    }
}

.avm_details_tabing .list {
    margin-top: 40px;
}

.avm_details_tabing .list h6 {
    margin-bottom: 10px;
    display: inline-block;
    font-size: 22px;
    color: #ee7435;
}

.avm_details_tabing .list ul li {
    font-size: 15px;
    line-height: 25px;
    color: #333333;
    position: relative;
    padding-left: 20px;
    margin-bottom: 15px;
}

.avm_details_tabing .list ul li::before {
    content: "";
    position: absolute;
    height: 7px;
    width: 7px;
    border-radius: 50%;
    background-color: #ee7435;
    top: 15px;
    left: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

section.objective_avm_exercise {
    background-color: #cce6cb;
    padding: 70px 0;
}

section.objective_avm_exercise .heading h2 {
    color: #000000;
    font-size: 25px;
    line-height: 34px;
}

section.objective_avm_exercise .heading p {
    font-size: 15px;
    line-height: 30px;
    color: #333;
}

@media (max-width: 576px) {
    section.objective_avm_exercise .heading p {
        text-align: justify;
    }
}

section.objective_avm_exercise .heading a {
    font-size: 16px;
    text-decoration: underline;
    color: #5c2d83;
    margin-top: 25px;
    display: inline-block;
}

section.objective_avm_exercise .heading a:hover {
    border-bottom: 1px solid #5c2d83;
}

section.objective_avm_exercise .content {
    margin-top: 30px;
}

section.objective_avm_exercise .content ul li {
    font-size: 15px;
    line-height: 25px;
    color: #333333;
    margin-top: 15px;
    padding-left: 20px;
    position: relative;
}

@media (max-width: 576px) {
    section.objective_avm_exercise .content ul li {
        text-align: justify;
    }
}

section.objective_avm_exercise .content ul li::before {
    content: "";
    position: absolute;
    height: 7px;
    width: 7px;
    border-radius: 50%;
    background-color: #57ac52;
    top: 10px;
    left: 0;
}

section.objective_avm_exercise .content ul li ul li {
    padding-left: 0;
}

section.objective_avm_exercise .content ul li ul li::before {
    position: static;
}

section.objective_avm_exercise .content a {
    font-size: 16px;
    color: #333333;
    margin-top: 40px;
    display: inline-block;
}

section.objective_avm_exercise .content a:hover {
    border-bottom: 1px solid #333;
}

section.objective_avm_exercise .banner_image {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.avm_cards {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border: 1px solid #b9bcc4;
    border-radius: 4px;
    padding: 20px 30px;
    margin-bottom: 25px;
}

@media (min-width: 992px) {
    .avm_cards {
        min-height: 103px;
    }
}

@media (min-width: 1200px) {
    .avm_cards {
        min-height: auto;
    }
}

.avm_cards img {
    margin-right: 25px;
}

.avm_cards p {
    margin-bottom: 0 !important;
    color: #566590 !important;
    font-size: 17px !important;
}

.read_more {
    font-size: 16px;
    color: #5c2d83;
    margin-top: 25px;
    display: inline-block;
}

.read_more:hover {
    border-bottom: 1px solid #5c2d83;
    color: #5c2d83;
}

.read_more_mra {
    font-size: 16px;
    color: #9acd97 !important;
    margin-top: 25px;
    display: inline-block;
}

.read_more_mra:hover {
   
    border-bottom: 1px solid #86FFBF;
    color: #86FFBF;
}

@media (max-width: 576px) {
    .mt_15_mob {
        margin-top: 15px;
    }
}

.mt_0 {
    margin-top: 0 !important;
}

 .activenav{
    background: white;
    width: 100%;
    text-align: center;
    color:#EE7435 !important;
    height: 100%;
}

.activemob{    
    color: #ee7435 !important;    
}

.investor{
    background-color:#ee7435;
    padding-top: 100px;
    padding-bottom: 100px;
    margin-top:100px;    
}

.investor .content ul li::before {
    content: "";
    position: absolute;
    height: 7px;
    width: 7px;
    border-radius: 50%;
    background-color:#f5ac86!important;
    top: 15px;
    left: 0;
    transform: translateY(-50%);
}
.banks{
    padding-top: 0px;
}
.banks .box{
    background-color:#3774e8;
    padding: 50px 20px 50px 50px;
}
.companies{
    padding-top: 0px;
}

.companies .box{
    background-color:#57ac52; 
    padding: 50px 20px 50px 50px;
}


.companies .content ul li::before {
    content: "";
    position: absolute;
    height: 7px;
    width: 7px;
    border-radius: 50%;
    background-color: #9acd97 !important;
    top: 15px;
    left: 0;
    transform: translateY(-50%);
}

.wm{
    margin-top:-50px ;
    height:auto;
}



@media (max-width: 576px) {
    .companies .box{
        background-color:#ee7435 ; 
        padding: 30px 10px 30px 10px;
    }

    .banks .box{
        background-color:#566590 ;
        padding: 30px 10px 30px 10px;
    }
   

    .wm{
        margin-top:0px ;   
        height:auto;
    }
   
   
}
.right-b:first-child{
    border-right: 2px solid #777;
    border-left: 2px solid transparent;
}
.activelang{
    color: #ee7435 !important;
}

.slick-next {
    right: 50px !important;
}

.slick-prev {
    left: 25px !important;
    z-index: 1;
}
@media (max-width: 767px) {
    .home_page .slick-next {
        right: 0px !important;
    }
    
    .home_page .slick-prev {
        left: 0px !important;
    }
}

/* .slick-list {
    height:auto !important;
} */
.testimonial-section .slick-list{
    max-width: 900px;
    margin: auto;
 
}

.slick-prev, .slick-next {
    top:40% !important;
    display: none !important;
 
}
.home_page_slider .slick-prev{
    left: auto !important;
    right: 100px !important;
} 
@media (max-width: 767px) {
    .home_page_slider .slick-prev{
        left: -30px !important;
        right: auto !important;
    } 
    .blog-insights.home_page_slider  .slick-prev,
    .popular_reports.home_page_slider  .slick-prev {
        left: -20px !important;
    }
    .home_page_slider  .slick-next {
        left: auto !important; 
        right: -34px !important;
    }
}

.testimonial-slider .slick-prev{
    left: 50px!important;


}
.testimonial-slider .slick-next{
    right: 50px!important;


}
.home_page_slider .slick-prev, 
.home_page_slider .slick-next{
    display: inline-block !important;
    top: -26% !important;
    width: 30px;
    height: 30px;
}
@media (max-width : 767px) {
    .home_page_slider .slick-prev, 
    .home_page_slider .slick-next{
        top: calc(50% - 20px) !important;  
    }
}
.testimonial-slider .slick-prev,
.testimonial-slider .slick-next{
 transform:none !important;
    top: 30% !important;
    right: 124px !important;

}
    @media(max-width: 992px) {
        .testimonial-slider .slick-prev,
        .testimonial-slider .slick-next{
            display: none !important;
        }
}
.home_page_slider .slick-prev:before, 
.home_page_slider .slick-next:before{
    top: 0;
    background-image: url('../../../assets/icons/next-prev.svg');
    background-position: 0%;
    content: '' !important;
    background-size: 68px;
    width: 22px;
    height: 30px;
    opacity: 1;
}
@media (max-width: 767px){
    .home_page_slider .slick-prev:before, 
    .home_page_slider .slick-next:before{
        background-position: 10px;
        background-size: 50px;
        width: 35px;
        height: 35px;
        background-repeat: no-repeat;
        background-color: rgb(255 255 255 / 80%);
        border-radius: 50%;
    }
}
.testimonial-slider .slick-prev:before, 
.testimonial-slider .slick-next:before{
        background-size: 124px !important;
        width: 30px !important;
        height: 54px !important;
       
    }

.home_page_slider .slick-next:before{
    transform: rotate(180deg);
}

[dir=rtl] .slick-prev {
    left: auto !important;
    right:50px !important;
}

[dir=rtl] .slick-next {
    left: 50px !important;
    right: auto !important;
}

 [dir=rtl] .testimonial-slider .slick-next {
    left: 0px !important;
    right:100px !important;
   
}
@media (max-width: 767px){
    html[dir=rtl] .home_page_slider  .slick-next {
        left: auto !important;
        right: -20px !important;
    }
    .home_page_slider  .slick-next {
        left: auto !important;
        right: 5px !important;
    }
    .blog-insights.home_page_slider  .slick-next,
    .popular_reports.home_page_slider  .slick-next {
        left: auto !important;
        right: 15px !important;
    }
}

.slick-track{
    min-width: 100% !important;
}


.showerrormessage{
    background-color:rgb(209, 35, 4);
    color:rgb(120, 10, 10);
    width:100%;
    height:50px;
    padding:15px;
    line-height:25px;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    position:relative;
    animation: messagebox 5s 5s forwards;
}
.showmessage{
    background-color:rgb(8, 237, 180);
    color:rgb(8, 93, 65);
    width:100%;
    height:50px;
    padding:15px;
    line-height:25px;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    position:relative;
    animation: messagebox 5s 5s forwards;
}


@keyframes messagebox {
        0%{left: 0px;}
        100% {left: -5000px;}
}

.show{
    display: block !important;
}

.hide{
    display: none !important;
}



