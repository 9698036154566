@mixin rtl($property, $ltr-value, $rtl-value) {
  #{$property}: $ltr-value;
  html[dir="rtl"] & {
    #{$property}: $rtl-value;
  }
}
//map control button
.control {
  @include rtl(right, 0, unset);
  @include rtl(left, unset, 0);
  ul {
    @include rtl(border-top-right-radius, unset, 10px);
    @include rtl(border-bottom-right-radius, unset, 10px);
    @include rtl(border-top-left-radius, 10px, unset);
    @include rtl(border-bottom-left-radius, 10px, unset);
    li {
      &:hover:last-child {
        @include rtl(border-bottom-right-radius, unset, 10px);
        @include rtl(border-bottom-left-radius, 10px, unset);
      }
      &:hover:first-child {
        @include rtl(border-top-right-radius, unset, 10px);
        @include rtl(border-top-left-radius, 10px, unset);
      }
    }
  }
}
.control-child {
  @include rtl(right, 50px, unset);
  @include rtl(left, unset, 50px);
  ul.child {
    border-radius: 10px;
    li {
      &:hover:last-child {
        border-bottom-right-radius :  10px;
        border-bottom-left-radius : 10px;
      }
      &:hover:first-child {
        border-top-right-radius :  10px;
        border-top-left-radius : 10px;
      }
      &.active:last-child {
        border-bottom-right-radius :  10px;
        border-bottom-left-radius : 10px;
      }
      &.active:first-child {
        border-top-right-radius :  10px;
        border-top-left-radius : 10px;
      }
    }
  }
}
// map save abd share button
.sharing-btn {
  @include rtl(right, 0, unset);
  @include rtl(left, unset, 0);
}
//map filter
.map-filter-position {
  @include rtl(right, 20px, unset);
  @include rtl(left, unset, 20px);
}
//city page
.heading,
ul.ul-style,
.body-content {
  @include rtl(text-align, left, unset);
  @include rtl(text-align, unset, right);
}
ul.ul-style {
  [dir="rtl"] & {
    padding: 0;
  }
}
.ul-style li span {
  @include rtl(margin-left, 10px, unset);
  @include rtl(margin-right, unset, 10px);
}

//@include
.close-filter-img {
  @include rtl(transform, rotate(0deg), rotate(180deg));
}
.txt_position {
  @include rtl(text-align, left !important, unset);
  @include rtl(text-align, unset, right !important);
}
.txt_position_Opp {
  @include rtl(text-align, right !important, unset);
  @include rtl(text-align, unset, left !important);
}
.propertyImg_div {
  // @include rtl(margin-right, 20px !important, unset);
  @include rtl(margin-left, unset, 20px !important);
}
[dir="rtl"] .search_m input{
  background-position: right 12px center !important;
}
.back-arrow {
  @include rtl(transform, rotate(0), rotate(180deg));
}
.prop-attribute-info {
  @include rtl(left, 0, unset);
  @include rtl(right, unset, 0);
}
.info_img {
  @include rtl(right, 0px, unset);
  @include rtl(left, unset, 0px);
}
.cross_feedback {
  @include rtl(right, -48px, unset);
  @include rtl(left, unset, -48px);  
}
.feedback_popup{
  @include rtl(right, 73px, unset);
  @include rtl(left, unset, 73px);
}
.cross_icon {
  @include rtl(left, 25px, unset);
  @include rtl(right, unset, 25px);
}
.cross_opp {
  @include rtl(right, 25px, unset);
  @include rtl(left, unset, 25px);
}
.mat-cal-icon {
  @include rtl(right, 5px, unset);
  @include rtl(left, unset, 5px);
}
.three_dots {
  @include rtl(right, 11px, unset);
  @include rtl(left, unset, 11px);
}
#crumbs {
  ul {
    @include rtl(text-align, left, unset);
    @include rtl(text-align, unset, right);
  }
}

.pmaindiv {
  .nrby_srvcs,
  .area,
  .more-text {
    @include rtl(text-align, left, unset);
    @include rtl(text-align, unset, right);
  }
}
.profile-header {
  .cross-btn {
    @include rtl(right, 25px, unset);
    @include rtl(left, unset, 25px);
  }
}
.b1.box {
  @include rtl(border-right, 1px solid #ededed, unset);
  @include rtl(border-left, unset, 1px solid #ededed);
  .box-list {
    @include rtl(border-right, none !important, unset);
    @include rtl(border-left, unset, none !important);
  }
}
#closeBar {
  .topbar {
    .logo {
      @include rtl(margin, 0 15px 0 0 !important, unset);
      @include rtl(margin, unset, 0 0 0 15px !important);
    }
  }
}
.nav-item {
  .radius-prop {
    @include rtl(border-radius, 6px 0px 0px 6px !important, unset);
    @include rtl(border-radius, unset, 0px 6px 6px 0px !important);
  }
  .radius-trend {
    @include rtl(border-radius, 0px 6px 6px 0px !important, unset);
    @include rtl(border-radius, unset, 6px 0px 0px 6px !important);
  }
}

.disclamier {
  button {
    @include rtl(border-right, none !important, unset);
    @include rtl(border-left, unset, none !important);
  }
}

.txt_right {
  @include rtl(text-align, right !important, unset);
  @include rtl(text-align, unset, left !important);
}
.footer-position {
  .footer-list {
    &:nth-of-type(1) {
      li:first-child::after {
        @include rtl(left, 14px !important, unset);
        @include rtl(right, unset, 14px !important);
      }
    }
  }
}
._direction {
  .txt_password {
    @include rtl(padding, 0 31px 0 7px !important, unset);
    @include rtl(padding, unset, 0 7px 0 31px !important);
  }
}
.eye_img {
  @include rtl(right, 0, unset);
  @include rtl(left, unset, 0);
}
li.activeProfile::after{
  @include rtl(right, 15px, unset !important);
  @include rtl(left, unset, 15px);
}
.close_align {
  @include rtl(right, 12px, unset);
  @include rtl(left, unset, 12px);
}
._direction {
  @include rtl(direction, ltr, unset);
  @include rtl(direction, unset, rtl);
}
.input_direction {
  @include rtl(direction, unset, rtl);
  @include rtl(padding-left, 35px, unset);
  @include rtl(padding-right, unset, 35px);
}
.input_icon_rtl {
  @include rtl(left, 10px, unset);
  @include rtl(right, unset, 10px);
}
.registerForm_icon_rtl {
  @include rtl(left, 2px, unset);
  @include rtl(right, unset, 2px);
}
.input_bgImage_align {
  @include rtl(background-position, top 9px left 15px !important, unset);
  @include rtl(background-position, unset, top 9px right 15px !important);
}
.top_input_bgImage_align {
  @include rtl(background-position, top 11px left 15px !important, unset);
  @include rtl(background-position, unset, top 11px right 15px !important);
}
.flex_direction_ar {
  @include rtl(flex-direction, row !important, unset);
  @include rtl(flex-direction, unset, row-reverse !important);
}
/* Breadcrumb */
#crumbs {
  ul {
    @include rtl(flex-direction, row !important, unset);
    @include rtl(flex-direction, unset, row-reverse !important);
    li {
      &:first-child {
        a {
          @include rtl(border-top-left-radius, 7px !important, 0 !important);
          @include rtl(border-top-right-radius, unset, 7px !important);
          @include rtl(border-bottom-left-radius, 7px !important, 0 !important);
          @include rtl(border-bottom-right-radius, unset, 7px !important);

          @include rtl(padding-left, 5px !important, unset);
          @include rtl(padding-right, unset, 10px !important);
        }
      }
      &:last-child {
        a {
          @include rtl(border-top-right-radius, 7px, unset);
          @include rtl(border-top-left-radius, unset, 7px !important);
          @include rtl(border-bottom-right-radius, 7px, unset);
          @include rtl(border-bottom-left-radius, unset, 7px !important);
        }
      }
      a {
        @include rtl(margin, 0px 1.5px 0 0.5px !important, unset);
        @include rtl(margin, unset, 0px 0.5px 0 1.5px !important);
        @include rtl(padding, 6px 15px 6px 30px !important, unset);
        @include rtl(padding, unset, 6px 30px 6px 15px !important);
        @media screen and (max-width:600px) {
          @include rtl(padding, 6px 6px 6px 25px !important, unset);
          @include rtl(padding, unset, 6px 25px 6px 6px !important);
        }
        &:hover {
          &::after {
            @include rtl(border-left-color, #555, unset);
            @include rtl(border-right-color, unset, #555);
          }
        }
        &:after {
          @include rtl(border-left, 16px solid #eee, unset);
          @include rtl(border-right, unset, 16px solid #eee);
          @include rtl(right, -16px, unset);
          @include rtl(left, unset, -16px);
        }
        &:before {
          @include rtl(border-left, 16px solid #fff, unset);
          @include rtl(border-right, unset, 16px solid #fff);
          @include rtl(left, 0px, unset);
          @include rtl(right, unset, 0px);
        }
      }
    }
  }
}
.propertyLevel_header {
  .sharing-btn_propertyLevel {
    button {
      &:first-child {
        @include rtl(margin-right, 10px, unset);
        @include rtl(margin-left, unset, 10px);
      }
    }
  }
}
.b1.box3 {
  @include rtl(border-left, 1px solid #cecece, unset);
  @include rtl(border-right, unset, 1px solid #cecece);
}
.profile_setting_switch {
  @include rtl(right, 5px !important, unset);
  @include rtl(float, right !important, unset);
  @include rtl(left, unset, 5px !important);
  @include rtl(float, unset, left !important);
}
.global_position {
  @include rtl(left, 0, unset);
  @include rtl(right, unset, 0);
}
.top-section {
  .fa-angle-left {
    @include rtl(transform, rotate(0deg), unset);
    @include rtl(transform, unset, rotate(180deg));
  }
}
.arrow{
  @include rtl(left, 40px, unset);
  @include rtl(right, unset, 40px);
  @include rtl(transform, rotate(0deg), unset);
  @include rtl(transform, unset, rotate(180deg));
}
@media screen and (max-width:767px) {
  .property_headers {
    .pro_filters {
      .fltr {
        .mat-form-field {
          @include rtl(margin, 0px 0px 0px 20px !important, unset);
          @include rtl(margin, unset, 0px 22px 0px 0px !important);
        }
        > span {
          @include rtl(margin, 0 0px 6px 20px !important, unset);
          @include rtl(margin, unset, 0 20px 6px 0px !important);
        }
      }
    }
  }
  .txt_clr_all {
    @include rtl(right, 25px, unset);
    @include rtl(left, unset, 25px);

    @include rtl(text-align, right, left);
  }
  
}

[dir="rtl"] .chk_privacypolicy{
  direction:rtl;
  text-align: right;
  .mat-checkbox .mat-checkbox-inner-container{
    margin-right: 0 !important;
  }
}
[dir="rtl"] .chk_privacypolicy{
  .switch-3{
    width: 46px;
  }
  .txt_switch{
    margin-right: 10px;
  }
}
.sub-menu{
  @include rtl(left, 0, unset);
  @include rtl(right, unset, 0);
}
.user-menu{
  @include rtl(right, 0, unset);
  @include rtl(left, unset, 0);
}
// .user-menu:after {
//   @include rtl(right, 15px, unset);
//   @include rtl(left, unset, 15px);
//   right: 15px;
// }
.map_60{
  @include rtl(right, 0, unset);
  @include rtl(left, unset, 0);
}

[dir="rtl"] .contact_details{
  .contact_options{
    background: linear-gradient(to left, #33d4a0, #32de67) !important;
  }
  .social_list{
    .icon_contact{
      margin-left:1rem;
      margin-right: unset !important;
    }
  }
  .contact_form{
    padding-left: 50px;
    padding-right: unset !important;
    p{
      font-size: 14px !important;
    }
  }
  .media-body{
    ul{
      li{
        &:first-child{
          margin-right: 0;
          margin-left: 35px !important;
        }
      }
    }
  }
}

[dir="rtl"] ol.final_inner_list{
  padding-right: 19px;
  padding-left: unset !important;
  li{
    &::before{
      right: -18px;
      left:unset !important
    }
  }
}

[dir="rtl"] .main_ol{
  > li{
    ol{
      li{
        > ol.lower_alpha{
          padding-right: 20px;
          padding-left: unset !important
        }
      }
    }
  }
}
[dir="rtl"] .p_align_left{
  text-align: right !important;
}
[dir="rtl"] section.case-study-header{
  transform:scaleX(-1);
  h1{
    transform:scaleX(-1);
    text-align: right;
  }
}

[dir="rtl"] .topbar{
  .logo_search span{
    padding-right: 0 !important;
    padding-left: 1rem;
  }
}
.lang {
  span {
    &:first-child{
      // @include rtl(border-right, 2px solid #ccc, unset);
      // @include rtl(border-left, unset, 2px solid #ccc);
      white-space: nowrap;
    }
  }
}
.property-legend{
  @include rtl(left, 10px, unset);
  @include rtl(right, unset, 10px);
}
.property-legend-m{
  @include rtl(left, 0px, unset);
  @include rtl(right, unset, 0px);
}


.prop_allow_cat {
  @include rtl(left, 10px, unset);
  @include rtl(right, unset, 10px);
  .toggle_strip{
    @include rtl(left, unset, 0px);
    @include rtl(right, 0px, unset !important);
  }
}
.prop_allow_cat.mobile_view{
  @include rtl(left, 2%, unset);
  @include rtl(right, unset, 2%);
}
.map-loader{
  @include rtl(left, unset, 0px);
  @include rtl(right, 0px, unset);
}
html[dir="rtl"] .prop_allow_cat{
  padding: 10px 10px 10px 30px !important;
}

#mobile_menu{
  @include rtl(left, unset, 0px);
  @include rtl(right, 0px, unset);
}
html[dir="rtl"] .ext_input{
  margin:0 10px 0 0 !important;
}
html[dir="rtl"] .broker_code_input{
  padding:0 10px 0 0 !important;
}
html[dir="rtl"] .company-form .form-group{
  text-align: right;
}
html[dir="rtl"] .reset_password_container .eye_img,
html[lang="en"] [dir="rtl"] .reset_password_container .eye_img{
  transform: translate(5px, -51%) !important;
}

.w-750{
  @include rtl(left, unset, -100px);
  @include rtl(right, -100px, unset);
}

html[lang="en"] [dir="rtl"] .txt_position {
  @include rtl(text-align, unset, right !important);
  @include rtl(text-align, left !important, unset);
  direction: ltr;
}
html[dir=rtl] .direction_.txt_position {
  direction: rtl !important;
}
html[lang="en"] [dir="rtl"] *{
  direction: ltr;
}
html[lang=en] [dir=rtl] ._direction {
  direction: ltr;
}

html[lang=en][dir=rtl] .eye_img{
  @include rtl(right, 0px, unset);
  @include rtl(left, unset, 0px);
}
html[lang=en] [dir=rtl] ._direction .txt_password {
  padding: 0 31px 0 7px !important;
}
html[lang=ar][dir=rtl] .input_box_prifix .prefix {
  padding-right: 0;
  min-width: 60px;
  text-align: left;
  padding-left: 0.5rem;
}
html[lang=ar][dir=rtl] .popup-div .ps-5,
html[lang=ar][dir=rtl] .popup-div_investment .ps-5 {
  padding-right: 3rem!important;
  padding-left: 1rem !important;
}
html[lang=ar][dir=rtl] .popup-div .pe-5 ,
html[lang=ar][dir=rtl] .popup-div_investment .pe-5 {
  padding-right: 1rem!important;
  padding-left: 3rem !important;
}
html[lang=ar] div[dir=ltr]:not(#crumbs) *{
  // direction: rtl;
}
html[lang=ar][dir=rtl] .contact_address .location li.address:before {
  left:auto;
  right: -25px;
}
html[lang=ar][dir=rtl] .upload-btn-wrapper input[type=file] {
  left:auto;
  right: 0px;
}
.properties_list_slider owl-stage > div,
.property_pagination *{
  direction: ltr !important;
}
html[lang=ar][dir=rtl] .map-spinner .background {
  left: auto;
  right: 50%;
}
html[lang=ar][dir=rtl] .property-legend div {
  float: right;
}
html[lang=ar][dir=rtl] .breadcrumb_details ul li a::after {
  left:auto;
  right: calc(100% + 5px);
}
html[lang=ar][dir=rtl] .topbar .links li.main_list:last-child {
  padding-right: 5px !important;
  padding-left: 10px !important;
}
html[lang=ar][dir=rtl] .home_page_slider .slick-prev {
  left: 8px !important;
  right: auto !important;
  @media (max-width: 767px) {
    left: 15px !important;
  }
}
html[lang=ar][dir=rtl] .how_it_works .custom-div::before {
  border-right-width: 18px;
  border-color: transparent #6449db transparent transparent;
  left: -28px;
  right: auto;
}
html[lang=ar][dir=rtl] .how_it_works .custom-div::after {
  right: auto;
  left: -35px;
}
html[lang=ar][dir=rtl] footer.footer_ec .social_media_icon ul {
  @media (min-width: 676px){
    float: left;
  }
}
html[lang=ar][dir=rtl] .slick-dots{
  direction: ltr;
}
html[lang=ar][dir=rtl] .un-order-list li {
  padding-left: 0px; 
  padding-right: 20px; 
}
html[dir=rtl] .un-order-list li::before{
  @include rtl(right, 0, unset);
  @include rtl(left, unset, 0);
}
html[dir=rtl] {
  .center_nav{
    @media (min-width:1600px){
      margin-left : 200px;
    }
  }
  .topbar .links li.main_list .sub-menu-full ul.child-menu li ul li {
    padding-left: 0;
    padding-right: 15px;
    &::before{
      left: auto;
      right: 4px;
    }
  }
  ul.list__item  li {
    list-style: arabic-indic;
  }
  .estater_logo {
    background-image: url('../ec-assets/images/Estater_Logo_Arabic.svg');
  }
  .middel-section .sub_child{
    label{
      padding: 10px 30px 10px 10px !important;
    }
    a {
      padding-right:35px !important;
      padding-left:0px !important;
      &::before{
        right:30px;
        left:auto;
      }
    }
  }
  .trusted_by.home_page .heading h2{
    @media (min-width:1300px){
      font-size: 2.8rem !important;
      line-height: 1;
    }
  } 
  .estater_logo {
    background-position: center bottom;
    background-size: 100%;
  }
  
  .banner_section .banner_section_inner .tab_content .tab_top_content h2 {
    @media (max-width: 767px) {
        font-size: 2.3rem !important;
    }
  }
  .bg-purple p{
    font-weight: 500 !important;
    padding: 0.91rem !important;
  }
  .banner_section_inner sup {
    left: -8px;
  }
  div[dir=ltr] .mat-mdc-optgroup-label {
    justify-content: flex-end;
  }
}