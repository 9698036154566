
.iti {
	display: block !important;
    position: relative;
}
.iti.separate-dial-code.iti--allow-dropdown.iti-sdc-2 .iti__selected-flag, .iti.separate-dial-code.iti--allow-dropdown.iti-sdc-3 .iti__selected-flag, .iti.separate-dial-code.iti--allow-dropdown.iti-sdc-4 .iti__selected-flag, .iti.separate-dial-code .iti__selected-flag {
    /* width: 60px !important; */
    position: absolute;
    top: 0;
    padding: 7px 5px;
    left: 0;
	border-right: 1px solid #ededed;
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
}
.iti .dropdown-menu.country-dropdown {
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
	border-color: #c7cace;
	margin-top: -1px;
    box-shadow: 0px 2px 6px 0px #ccc;
}

.iti .iti__country-list {
	box-shadow: none;
	margin: 0;
    padding: 3px;
	width: 100%;
	max-height: 183px;
    list-style: none;
    overflow: auto;
}

.iti__flag-container.open + input {
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
}

.iti .search-container input {
	border-color: #c7cace;
	border-radius: 0;
	padding: 5px 10px;
}

.iti .search-container input:focus {
	outline: none;
}
li.iti__country {
    padding: 5px !important;
    border-bottom: 1px solid #ddd !important;
    color: #555 !important;
}
li.iti__country:last-child {
    border-bottom: none !important;
}

@media screen and (max-width: 479px) {
	.iti .iti__country-list {
		width: 88.3vw;
	}
}

ngx-intl-tel-input input {
    width: 100%;
	/* padding: 7px 7px 7px 80px !important; */
    border: none;
	border-bottom: 1px solid #c7cace;
	border-radius: 2px !important;
	font-size: 15px !important;
}


ngx-intl-tel-input input::-webkit-input-placeholder {
	color: #bac2c7;
}

ngx-intl-tel-input input:-ms-input-placeholder {
	color: #bac2c7;
}

ngx-intl-tel-input input::-ms-input-placeholder {
	color: #bac2c7;
}

ngx-intl-tel-input input::placeholder {
	color: #bac2c7;
}

ngx-intl-tel-input input[disabled] {
	background-color: #e5eaf1;
}
ngx-intl-tel-input input.profile_tel {
    padding: 0.375rem 0.375rem 0.375rem 80px !important;
    border-radius: 0.25rem !important;
    font-size: 14px !important;
    height: 41px !important;
}
ngx-intl-tel-input input.query_tel {
    padding: 0.375rem 0.375rem 0.375rem 100px !important;
}
ngx-intl-tel-input input.profile_tel[disabled]::placeholder {
    color: #c9c9c9;
}
ngx-intl-tel-input input.profile_tel::placeholder {
    color: #c9c9c9;
}