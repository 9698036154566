html[dir="rtl"] {
    text-align: right;
}
html[dir="rtl"] .mobile-header img{
       right: 10px;
       left: auto;
}
html[dir="rtl"] .text_right_ar {
    text-align: right;
}

html[dir="rtl"] .text_left_ar {
    text-align: left !important;
}
html[dir="rtl"] .force-ltr {
    direction: ltr !important; 
    text-align: left;          
  }
html[dir="rtl"] .row_reverse_ar {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
}

html[dir="rtl"] .col_reverse_ar {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
}

html[dir="rtl"] ul.main_list_item li {
    padding-left: inherit;
    padding-right: 20px;
}

html[dir="rtl"] ul.main_list_item li::before {
    right: 0;
    left: inherit;
}

html[dir="rtl"] ul.arb_list li {
    padding-right: 20px;
    padding-left: 0;
}

html[dir="rtl"] ul.arb_list li::before {
    right: 0;
}

html[dir="rtl"] ul.arb_list li p {
    padding-right: 20px;
    padding-left: 0;
}

html[dir="rtl"] ul.arb_list li p::before {
    right: 0;
}

html[dir="rtl"] ul.arb_list.casestudy_points li {
    padding-right: 0px;
    padding-left: 0;
}

html[dir="rtl"] ul.arb_list.casestudy_points li p {
    padding-right: 20px;
    padding-left: 0;
}

html[dir="rtl"] ul.arb_list.casestudy_points li p::before {
    right: 0;
}

html[dir="rtl"] header.header .navbar .navbar-header .navbar-brand {
    right: 20px;
    left: inherit;
    margin-right: 0;
}

html[dir="rtl"] header.header .navbar .navbar-header .navbar-brand img {
    -webkit-transform: rotate(0);
    transform: rotate(0);
}

@media (max-width: 575px) {
    html[dir="rtl"] header.header .navbar .navbar-header .navbar-brand img.for_mobile {
        margin-right: 0;
    }
}

@media (max-width: 576px) {
    html[dir="rtl"] header.header .navbar .nav-links {
        left: -100%;
        right: inherit;
    }
}

html[dir="rtl"] header.header .navbar .nav-links ul.links li.lang {
    padding-right: 30px;
    padding-left: 0;
}

html[dir="rtl"] header.header .navbar .nav-links ul.links li .sub-menu {
    right: 0;
}

/* html[dir="rtl"] header.header .navbar .nav-links ul.links li:last-child {
    padding-right: 10px;
    padding-left: 0;
} */

@media (max-width: 576px) {
    html[dir="rtl"] header.header .navbar .nav-links .btn_close {
        left: 15px;
        right: initial;
    }
}

html[dir="rtl"] section.avm_module .card .content ul li {
    padding-right: 15px;
    padding-left: 0;
    font-size: 18px;
    line-height: 26px;
}

html[dir="rtl"] section.avm_module .card .content ul li::before {
    right: 0;
}

html[dir="rtl"] section.avm_module .card .content p {
    font-size: 18px;
    line-height: 26px;
    max-width: 275px;
}

html[dir="rtl"] section.top_banner .banner.bnr_1 .inner_container {
    padding-left: 0;
    padding-right: 15px;
    text-align: right;
    background-position: bottom -1px right 20px;
}

html[dir="rtl"] .rotate_img {
    transform: rotate(180deg);
}

@media (max-width: 575px) {
    html[dir="rtl"] section.top_banner .banner.bnr_1 .inner_container {
        text-align: center;
    }
}

html[dir="rtl"] section.top_banner .banner.bnr_2 .inner_container {
    padding-left: 15px;
    padding-right: 60px;
    background-position: bottom -1px left 0px;
    text-align: right;
}

@media (max-width: 576px) {
    html[dir="rtl"] section.top_banner .banner.bnr_2 .inner_container {
        padding-left: 15px;
        padding-right: 0px !important;
        text-align: center;
    }
}

html[dir="rtl"] section.popular_trends .trends_container .trends_heading .arrow .fa:first-child {
    margin-left: 25px;
    margin-right: inherit;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

html[dir="rtl"] section.popular_trends .trends_container .trends_heading .arrow .fa:last-child {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

html[dir="rtl"] section.popular_trends .trends_container .trends_column .item .item_content {
    right: 20px;
    left: inherit;
}

html[dir="rtl"] section.popular_reports .reports_heading .arrow a:first-child .mat-icon{
    margin-left: 25px;
    margin-right: inherit;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

html[dir="rtl"] section.popular_reports .reports_heading .arrow a:last-child .mat-icon {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

html[dir="rtl"] section.popular_reports .reports_container .report_columns .item {
    padding: 0px 0px 0px 15px;
}

html[dir="rtl"] section.estater_banner_design .web_banner,
html[dir="rtl"] section.em_banner .web_banner,
html[dir="rtl"] section.MI_banner_design .web_banner,
html[dir="rtl"] section.estater_banner_design .web_banner {
    right: inherit;
    left: 15px;
}

html[dir="rtl"] .em_overview section.em_about .content p {
    padding-left: 30px;
    padding-right: inherit;
}

html[dir="rtl"] .em_overview section.avm_module .heading h2 span {
    margin-left: 20px;
    margin-right: inherit;
}

html[dir="rtl"] .em_overview section.feature_estater_meter .prod_links .tab-content #consumer .list p {
    padding-right: 20px;
    padding-left: 0;
}

html[dir="rtl"] .em_overview section.feature_estater_meter .prod_links .tab-content #consumer .list p::before {
    right: 0;
    left: inherit;
}

html[dir="rtl"] .mi_reports section.prominent_mi_reports .tab {
    float: right;
}

html[dir="rtl"] .mi_reports section.prominent_mi_reports .tab button {
    text-align: right;
}

html[dir="rtl"] section.challenges_benegit .col_benefits .cont_chll_benfit,
html[dir="rtl"] .data_science section.types_of_data .col_app_datascience .cont_chll_benfit,
html[dir="rtl"] .machine_learning section.ml_data_driven .col_app_datascience .cont_chll_benfit,
html[dir="rtl"] .mra section.regression_anal .col_app_datascience .cont_chll_benfit,
html[dir="rtl"] .mra section.mra_chall .col_app_datascience .cont_chll_benfit {
    padding-left: inherit !important;
    padding-right: 50px;
}

@media (max-width: 576px) {
    html[dir="rtl"] section.challenges_benegit .col_benefits .cont_chll_benfit,
    html[dir="rtl"] .data_science section.types_of_data .col_app_datascience .cont_chll_benfit,
    html[dir="rtl"] .machine_learning section.ml_data_driven .col_app_datascience .cont_chll_benfit,
    html[dir="rtl"] .mra section.regression_anal .col_app_datascience .cont_chll_benfit,
    html[dir="rtl"] .mra section.mra_chall .col_app_datascience .cont_chll_benfit {
        padding-right: 15px;
    }
}

html[dir="rtl"] footer .explore_list .footer_tabing li {
    margin-left: 40px;
    margin-right: inherit;
}

html[dir="rtl"] footer .social_media_icon ul li:first-child {
    padding-left: 20px;
    padding-right: 0;
}

html[dir="rtl"] .slick-slide {
    float: left;
}

html[dir="rtl"] .slick-slider .slick-track,
html[dir="rtl"] .slick-slider .slick-list {
    direction: ltr;
}

@media (max-width: 576px) {
    html[dir="rtl"] footer .footer_text p {
        text-align: right;
    }
}

html[dir="rtl"] .contact_form .col_benefits .contact_address .info-details img,
html[dir="rtl"] .contact_form .col_benefits .contact_address .info_details img,
html[dir="rtl"] .contact_form .col_benefits .contact_address .location img,
html[dir="rtl"] .contact_form .col_benefits .contact_address .follow_us img {
    margin-left: 25px;
    margin-right: inherit;
}

html[dir="rtl"] .btn_subscribe .subscribe-btn {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
}

@media (max-width: 576px) {
    html[dir="rtl"] .btn_subscribe .subscribe-btn {
        border-radius: 4px !important;
    }
}

@media (min-width: 577px) {
    html[dir="rtl"] .contact_form .col_challenges .cont_chll_benfit {
        padding-right: 15px;
        padding-left: 40px;
    }
}

html[dir="rtl"] .contact_form .col_benefits .contact_address .follow_us .follow_socialmedia li a:first-child {
    margin-left: 30px;
    margin-right: inherit;

 
}
@media(max-width:767px) {
    html[dir="rtl"] .contact_form .col_benefits .contact_address .follow_us .follow_socialmedia li a:first-child {
        margin-left: 12px;
    }
    
}

html[dir="rtl"] .label_check .checkbox-inline {
    padding-right: 10px;
    padding-left: 0 !important;
}

html[dir="rtl"] .text_right {
    text-align: right;
}

html[dir="rtl"] .avm_details_tabing .tab a {
    padding: 25px 0px 25px 16px;
    text-align: right;
}

html[dir="rtl"] .avm_details_tabing .inner_content .tabcontent {
    padding: 0px 90px 0 0px;
}

html[dir="rtl"] .avm_details_tabing .list ul li {
    padding-left: inherit;
    padding-right: 20px;
}

html[dir="rtl"] .avm_details_tabing .list ul li::before {
    right: 0;
    left: inherit !important;
}

html[dir="rtl"] .breadcrumb_txt img {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

html[dir="rtl"] .avm_cards img {
    margin-right: inherit;
    margin-left: 25px;
}

html[dir="rtl"] .privacy_content .main_ol>li ol .lower_alpha {
    padding-right: 20px;
    padding-left: inherit !important;
}

html[dir="rtl"] .privacy_content ol.final_inner_list {
    padding-right: 19px;
    padding-left: inherit !important;
}

html[dir="rtl"] .privacy_content ol.final_inner_list li::before {
    right: -18px;
    left: inherit !important;
}

html[dir="rtl"] .help_search input {
    background-position: right 13px center;
}

html[dir="rtl"] .faq_question .tabcontent {
    padding: 0px 90px 0 0px;
}

html[dir="rtl"] .faq_question .tab button {
    padding: 25px 0px 25px 16px;
    text-align: right;
}

html[dir="rtl"] .faq_question .tab button:first-child {
    padding-top: 0;
}

html[dir="rtl"] .query_page .custom-select::after {
    right: inherit;
    left: 0;
}

html[dir="rtl"] .query_page .button-cancel {
    margin-left: inherit;
    margin-right: 20px;
}

html[dir="rtl"] .about_what_we_do section.strategy_involves .segments .heading h3::before {
    right: 0;
}

html[dir="rtl"] .coverage_database .cards .table_content table tr th:last-child {
    border-left: inherit;
    border-right: 1px solid #fff;
}

html[dir="rtl"] .about_what_we_do section.estater_services .heading-p p {
    text-align: right !important;
}

html[dir="rtl"] .client_login {
    margin-right: inherit;
}

html[dir="rtl"] .request_demo {
    margin-left: 1rem;
}

html[dir="rtl"] .client_login {
    white-space: nowrap;
    margin-left: 0;
    width: auto;
    min-width: auto;
}

html[dir="rtl"] .avm_details_tabing .content p {
    text-align: right;
}

html[dir="rtl"] header.header nav .navbar .mob .lang_mob {
    margin-right: inherit;
    margin-left: 20px;
}
html[dir="rtl"] .mobile-header .lang_mob {
    margin-right: inherit;
    margin-left: 20px;
}
@media (max-width: 576px) {
    html[dir="rtl"] header.header .sub_nav ul {
        margin-left: 0px;
        overflow: auto;
    }
}

@media (min-width: 577px) {
    html[dir="rtl"] header.header .sub_nav ul {
        margin-left: 0px;
    }
}

@media (min-width: 992px) {
    html[dir="rtl"] header.header .sub_nav ul {
        margin-left: 0px;
    }
}

@media (min-width: 1200px) {
    html[dir="rtl"] header.header .sub_nav ul {
        margin-left: -5px;
    }
}
html[dir="rtl"] .watch_video a::before{
    left: calc(8px - 0px) !important;
}
html[dir="rtl"] .default_direction{
    direction: ltr;
    text-align: right;
}

html[dir="rtl"] .brokerDetails .breadcrumb_details ul li a:after {
    left: auto;
    right: calc(100% + 5px);
}
html[dir="rtl"] .site_map ul ul{
    padding: 0 30px 0 0;
}
html[dir="rtl"] .brokerDetails .detail_container,
html[dir="rtl"] .reset_input {
    padding-left: 0 !important;
    padding-right: 30px !important;
}
html[dir="rtl"] .name_company_inner{
    text-align: right !important;
    padding-left: 5px;
    padding-right: 0 !important;
}
html[dir="rtl"] .right-b:first-child{
    border-left: 2px solid #777;
    border-right: 2px solid transparent;
}
html[dir="rtl"] .setp .progressbar li.m2:after{
     display: none;
}
html[dir="rtl"] .setp .progressbar li.m1:after{
    content: "" !important;
    position: absolute;
    width: 32px;
    height: 2px;
    background: #777;
    top: 18px;
    left: -32px;
    z-index: -1;
}
html[dir="rtl"] .setp .progressbar li.m1{
    margin-left: 15px;
    margin-right: 0;
}
html[dir="rtl"] .setp .progressbar li.m2{
    margin-right: 15px;
    margin-left: 0;
}
html[dir="rtl"] .w-80.land_vacant {
    width: 84%;
}
html[dir="rtl"] .bottom_shade{
    left: auto !important;
    right: -10px;
}
html[dir="rtl"] .property_info_detail .propertyImg_div {
    left: auto;
    right: calc(100% - 40px);
}
html[dir="rtl"] .pmaindiv .property_sub_section {
    padding: 10px 15px 10px 40px !important;
}
html[dir="rtl"] .pmaindiv .property_sub_section .propertyCat_list li{
    border-right-width: 0px !important;
    border-left: 1px solid #644fe8;
}
/*# sourceMappingURL=arb.css.map */